import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import LoginPage from './pages/authorization/LoginPage';
import JobOrderListPage from './pages/JobOrderListPage';
import TransporterJobOrderDetailPage from './pages/transporter/JobOrderDetailPage';
import TransporterStuffingPage from './pages/transporter/StuffingPage';
import TransporterVehicleFormPage from './pages/transporter/VehicleFormPage';
import VehicleLoadsPage from './pages/VehicleLoadsPage';
import ReceiverJobOrderDetailPage from './pages/receiver/JobOrderDetailPage';
import SiteSettingPage from './pages/receiver/SiteSettingPage';
import SecurityVehicleCheckPage from './pages/security/VehicleCheckPage';
import SecurityJobOrderDetailPage from './pages/security/JobOrderDetailPage'
import ResetPasswordPage from './pages/authorization/ResetPasswordPage'
import NewUserPasswordPage from './pages/authorization/NewUserPasswordPage'
import Error404Page from './pages/Error404Page'
import Constants from './utilities/constants'
import moment from 'moment'

function Routes() {
    return (
        <Switch>
            <LandingRoute path="/" exact />
            <Route path="/not_found" exact render={() => <Error404Page />} />
            <LoginRoute path="/login" exact>
                <LoginPage />
            </LoginRoute>
            <LoginRoute path="/forgot_password" exact>
                <ResetPasswordPage />
            </LoginRoute>
            <LoginRoute path="/new_user" exact>
                <NewUserPasswordPage />
            </LoginRoute>
            <PrivateRoute path="/job_order" exact>
                <JobOrderListPage />
            </PrivateRoute>
            <PrivateRoute path="/transporter/detail" exact>
                <RoleRoute allowedRole={Constants.allowedRole.transporter}>
                    <TransporterJobOrderDetailPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/transporter/vehicle_form" exact>
                <RoleRoute allowedRole={Constants.allowedRole.transporter}>
                    <TransporterVehicleFormPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/transporter/item_loads" exact>
                <RoleRoute allowedRole={Constants.allowedRole.transporter}>
                    <VehicleLoadsPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/transporter/stuffing" exact>
                <RoleRoute allowedRole={Constants.allowedRole.transporter}>
                    <TransporterStuffingPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/receiver/detail" exact>
                <RoleRoute allowedRole={Constants.allowedRole.receiver}>
                    <ReceiverJobOrderDetailPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/receiver/site_setting" exact>
                <RoleRoute allowedRole={Constants.allowedRole.receiver}>
                    <SiteSettingPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/security/detail" exact>
                <RoleRoute allowedRole={Constants.allowedRole.security}>
                    <SecurityJobOrderDetailPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/security/vehicle_check" exact>
                <RoleRoute allowedRole={Constants.allowedRole.security}>
                    <SecurityVehicleCheckPage />
                </RoleRoute>
            </PrivateRoute>
            <PrivateRoute path="/security/item_loads" exact>
                <RoleRoute allowedRole={Constants.allowedRole.security}>
                    <VehicleLoadsPage />
                </RoleRoute>
            </PrivateRoute>
            <Route>
                <Redirect to="/not_found" />
            </Route>
        </Switch>
    );
}

function IsLoggedIn() {
    const access_token = localStorage.getItem('access_token');
    const token_expiration = localStorage.getItem('access_token_exp');
    let isExpired = false;
    if (token_expiration) {
        isExpired = moment().isAfter(moment(token_expiration));
        if (isExpired) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("access_token_exp");
        }
    }

    return access_token !== null && !isExpired;
}

function PrivateRoute({ children, path, ...rest }) {
    if (IsLoggedIn()) {
        return <Route {...rest} render={() => children} />
    }

    return <Redirect to="/" />;
}

function RoleRoute({ children, allowedRole, ...rest }) {
    const role = JSON.parse(localStorage.getItem('current_user')).roleKey;
    if (role.indexOf(allowedRole) >= 0) {
        return children;
    }

    return <Redirect to="/" />
}

function LoginRoute({ children, ...rest }) {
    if (IsLoggedIn()) {
        return <Redirect to="/" />
    }

    return <Route {...rest} render={() => children} />
}

function LandingRoute({ children, ...rest }) {
    if (IsLoggedIn()) {
        return <Redirect to="/job_order" />
    }

    return <Redirect to="/login" />
}

export default Routes;