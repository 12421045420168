import axios from 'axios';

export const getVehicleChecklist = () => {
    return axios.get(window.Configuration['API_URL'] + '/api/SecurityCheck/GetAllVehicleChecklist')
}

export const submitVehicleApproval = (data) => {
    return axios.post(window.Configuration['API_URL'] + '/api/JobOrder/SubmitSecurityVehicleApproval', data)
}

export const getVehicleChecklistResult = (cargoId) => {
    return axios.get(window.Configuration['API_URL'] + '/api/SecurityCheck/GetVehicleChecklistResult/' + cargoId)
}