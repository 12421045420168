import React from 'react'
import { Box } from '@material-ui/core';
import NoDataComponent from './NoDataComponent';
import VehicleListItem from './VehicleListItem';

const VehicleList = props => {
    const { onSelected, cargoes, page } = props;
    const selectVehicle = (id) => {
        if (onSelected) onSelected(id)
    }

    if ((cargoes ?? []).length === 0) {
        return <NoDataComponent />
    }

    const getStatus = (cargo) => {
        let status = null;
        switch (page) {
            case 'vehicle_check': status = cargo.vehicleCheckStatusName; break;
            case 'load_approval_transporter': status = cargo.transporterLoadApprovalStatusName; break;
            case 'load_approval_soc': status = cargo.socLoadApprovalStatusName; break;
            default: status = cargo.statusName; break;
        }
        return status;
    }

    return (
        <Box p={1}>
            {(cargoes ?? []).map((cargo) => {
                return (
                    <VehicleListItem onClick={() => selectVehicle(cargo.id)} cargo={cargo} status={getStatus(cargo)} />
                )
            })}
        </Box>
    )
}

export default VehicleList;
