import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#0e2b63",
      main: "#0e2b63",
      dark: "#0e2b63",

    },
    background: {
      default: "#f1f2f3",
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
});
