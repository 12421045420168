import { Card, CardContent, Typography, Grid, Box, Divider } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Loader from '../components/Loader';
import NoDataComponent from '../components/NoDataComponent';
import PageWrapper from '../components/PageWrapper';
import { getTransporterJobOrderList, getSecurityJobOrderList, getReceiverJobOrderList } from '../services/jobOrderService';
import InfiniteScroll from 'react-infinite-scroll-component';
import constants from '../utilities/constants';
import { HomeOutlined, HomeWorkOutlined, SecurityOutlined, SettingsRemoteOutlined, WidgetsOutlined, WorkOutline } from '@material-ui/icons';
import Pill from '../shared/Pill'
import 'react-toastify/dist/ReactToastify.css';

const JobOrderListPage = props => {
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState("");
    const [site, setSite] = useState(null)
    const { t } = useTranslation();
    const toastRef = useRef(null); 

    const transporterRedirectToDetail = (id) => {
        history.push('/transporter/detail?id=' + id)
    }

    const securityRedirectToDetail = (id) => {
        history.push('/security/detail?id=' + id)
    }

    const receiverRedirectToDetail = (id) => {
        history.push('/receiver/detail?id=' + id)
    }

    const onJobOrderSelected = (id) => {
        localStorage.removeItem('selected_tab');
        if ((role ?? []).indexOf(constants.allowedRole.transporter) >= 0) {
            transporterRedirectToDetail(id);
        } else if ((role ?? []).indexOf(constants.allowedRole.receiver) >= 0) {
            receiverRedirectToDetail(id);
        } else if ((role ?? []).indexOf(constants.allowedRole.security) >= 0) {
            securityRedirectToDetail(id);
        }
    }

    useEffect(() => {
        if (page === 0) {
            setPage(1);
            return;
        }
        const loadJobOrderList = async (size = 10) => {
            if (page === 1) setLoading(true);
            try {
                let getJobOrderListFn = () => ({
                    data: { success: true, payload: { items: [], totalItems: 0 } }
                });
                if ((role ?? []).indexOf(constants.allowedRole.transporter) >= 0) {
                    getJobOrderListFn = getTransporterJobOrderList;
                } else if ((role ?? []).indexOf(constants.allowedRole.receiver) >= 0) {
                    getJobOrderListFn = getReceiverJobOrderList;
                } else if ((role ?? []).indexOf(constants.allowedRole.security) >= 0) {
                    getJobOrderListFn = getSecurityJobOrderList;
                }
                const { data } = await getJobOrderListFn(page, size);
                console.log(data)
                if (data && data?.success) {
                    let newItems = [];
                    if (page === 1) {
                        newItems = (data?.payload?.items ?? []);
                    } else {
                        newItems = [...items, ...(data?.payload?.items ?? [])];
                    }
                    setItems(newItems);
                    setTotalItems(data?.payload?.totalItems)
                }
            } catch { }
            if (page === 1) setLoading(false);
        }
        
        loadJobOrderList();
    }, [page, role])

    useEffect(() => {
        setPage(0);
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (currentUser.roleKey) setRole(currentUser.roleKey)
        if (currentUser.site && currentUser.site.length > 0) {
            const sites = currentUser.site.filter(x => x.roleKey === constants.allowedRole.receiver)
            setSite(sites.length > 0 ? sites[0] : null);
        }
    }, [])

    const fetchData = () => {
        setPage(page + 1);
    }

    const refresh = () => {
        setPage(0)
    }
    return (
        <PageWrapper title={t('jobOrder')}>
            {/* <Box my={3} px={2}>
                <Grid container>
                    <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>Date</strong></Typography></Grid>
                    <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">Monday, 19-10-2020</Typography></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>Transporter</strong></Typography></Grid>
                    <Grid item xs={8} ><Typography variant="body1" gutterBottom color="textSecondary" className="text-right" >Multi Logistic</Typography></Grid>
                </Grid>
            </Box> */}
            {loading &&
                <Loader />
            }
            {!loading && (items ?? []).length === 0 &&
                <NoDataComponent />
            }

            <Box p={1}>
                <InfiniteScroll
                    dataLength={items.length}
                    hasMore={(items.length !== totalItems)}
                    next={fetchData}
                    loader={<Loader />}
                    endMessage={<></>}
                    refreshFunction={refresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}

                    pullDownToRefreshContent={
                        <Typography variant="body2" className="text-center" >&#8595; {t('pullToRefresh')}</Typography>
                    }
                    releaseToRefreshContent={
                        <Typography variant="body2" className="text-center" >&#8593; {t('releaseToRefresh')}</Typography>
                    }
                >
                    {
                        items.map((item) => (
                            <Card onClick={() => onJobOrderSelected(item.id)} key={item.id} className="card card-gradient mb-2">


                                <CardContent>

                                    {/* title */}
                                    <Box mb={2}>
                                        <Typography variant="h6" gutterBottom color="primary">
                                            {`#${item?.joNumber} - (${item?.jobOrderDateString}) `}
                                        </Typography>
                                    </Box>

                                    <Divider light />

                                    {/* info */}
                                    <Box mt={2} mb={1}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle2" color="primary" gutterBottom> {t('transporter')} </Typography>
                                            </Grid>
                                            <Grid item xs={8} container justify="flex-end">
                                                <Typography variant="subtitle2" color="textSecondary" gutterBottom className="text-right"> {item?.transporter?.companyName} </Typography>
                                            </Grid>
                                        </Grid>

                                        <Box >
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle2" color="primary" gutterBottom> {t('slotTime')} </Typography>
                                                </Grid>
                                                <Grid item xs={8} container justify="flex-end">
                                                    <Typography variant="subtitle2" color="textSecondary" gutterBottom >  {item?.slotTimeStart} - {item?.slotTimeEnd} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box >
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle2" color="primary" gutterBottom> {t('obd')} </Typography>
                                                </Grid>
                                                <Grid item xs={8} container justify="flex-end">
                                                    <Typography variant="subtitle2" color="textSecondary" gutterBottom align="right" >  {item?.obdNumber} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle2" color="primary" gutterBottom> Info </Typography>
                                            </Grid>
                                            <Grid item xs={8} container justify="flex-end">

                                                {
                                                    (item?.isMultiPick ?? false) ? (<Pill color="info"><WorkOutline fontSize="small" /></Pill>) : null
                                                }

                                                {
                                                    (item?.isMultiDrop ?? false) ? (<Pill color="success"><WidgetsOutlined fontSize="small" /></Pill>) : null
                                                }

                                                {
                                                    (item?.isUsingEscort ?? false) ? (<Pill color="warning"><SecurityOutlined fontSize="small" /></Pill>) : null
                                                }


                                            </Grid>
                                        </Grid>
                                    </Box>


                                    {/* route */}
                                    <Grid container>
                                        <Grid item xs={4} container direction="column">
                                            <HomeOutlined color="error" />
                                            <Typography variant="subtitle2" color="textSecondary" >{item?.siteOrigin?.name}</Typography>
                                        </Grid>

                                        <Grid item xs={4} container direction="column" justify="center" >
                                            <Divider light />
                                        </Grid>

                                        <Grid item xs={4} container direction="column" justify="flex-end" alignItems="flex-end">
                                            <HomeWorkOutlined color="error" />
                                            <Typography variant="subtitle2" className="text-right" color="textSecondary" >{(role ?? []).indexOf(constants.allowedRole.receiver) >= 0 ? site.name : item?.siteDestination?.name}</Typography>

                                        </Grid>
                                    </Grid>

                                            

                                    {/* <Typography><b>{t('documentNo')}</b> {item?.joNumber}</Typography>
                                    <Typography><b>{t('date')}</b> {item?.jobOrderDateString}</Typography> */}
                                    {/* <Typography><b>{t('origin')}</b> {item?.siteOrigin?.name}</Typography> */}
                                    {/* <Typography><b>{t('destination')}</b> {item?.siteDestination?.name}</Typography> */}
                                    {/* <Typography><b>{t('multipick')}</b> {(item?.isMultiPick ?? false) ? t('yes') : t('no')}</Typography>
                                    <Typography><b>{t('multidrop')}</b> {(item?.isMultiDrop ?? false) ? t('yes') : t('no')}</Typography>
                                    <Typography><b>{t('escort')}</b> {(item?.isUsingEscort ?? false) ? t('yes') : t('no')}</Typography> */}
                                    {/* <Typography><b>{t('transporter')}</b> {item?.transporter?.companyName}</Typography> */}
                                    {/* <Typography><b>{t('slotTime')}</b> {item?.slotTimeStart} - {item?.slotTimeEnd}</Typography> */}
                                </CardContent>


                            </Card>

                        ))
                    }
                </InfiniteScroll>
            </Box>
        </PageWrapper>
    );
}

export default JobOrderListPage;