import axios from 'axios';

export const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('files', file)
    return axios.post(`${window.Configuration['API_URL']}/api/BlobDocument/Upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const getFile = (id) => {
    return axios.get(`${window.Configuration['API_URL']}/api/BlobDocument/GetBlob/${id}`)
}