import { Box, Button, Container, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import MapsContainer from '../../components/MapsContainer';
import PageWrapper from '../../components/PageWrapper'
import { getSiteDetail, updateSite } from '../../services/siteService';
import imgLoading from '../../assets/images/loading.gif'
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';

const SiteSettingPage = (props) => {
    const { t } = useTranslation();
    const [picName, setPicName] = useState('')
    const [picPhone, setPicPhone] = useState('')
    const [address, setAddress] = useState('')
    const [radius, setRadius] = useState(1000);
    const [locationType, setLocationType] = useState("1") // 1 = radius, 2 = geofence
    const [point, setPoint] = useState(null)
    const [geofencePoints, setGeofencePoints] = useState([])
    const userId = JSON.parse(localStorage.getItem("current_user")).userId
    const [site, setSite] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(true)

    const onMarkerPositionChanged = (lat, lon) => {
        setPoint({
            lat: lat,
            lng: lon
        })
    }

    const onGeofenceChanged = (polygon) => {
        setGeofencePoints(polygon)
    }

    const getDetail = async () => {
        setLoading(true)
        try {
            const { data } = await getSiteDetail(userId)
            if (data && data?.payload) {
                const siteData = data?.payload?.site ?? {};
                setSite(siteData)
                setPicName(siteData.picName)
                setPicPhone(siteData.picPhone)
                setAddress(siteData.address)
                setLocationType(siteData.radiusType + "")
                if (siteData.latitude && siteData.longitude) {
                    setPoint({
                        lat: siteData.latitude,
                        lng: siteData.longitude
                    })
                }
                setRadius(siteData.radius)
                setGeofencePoints(siteData.geofenceList ?? [])
            }
        } catch { }
        setLoading(false)
    }

    const save = async () => {
        setIsSaving(true)
        const newSite = {
            ...site,
            picName: picName,
            picPhone: picPhone,
            address: address,
            radiusType: parseInt(locationType),
            latitude: point?.lat,
            longitude: point?.lng,
            radius: parseInt(radius) || 0,
            geofenceList: geofencePoints,
            geofence: JSON.stringify(geofencePoints)
        }

        try {
            const { data } = await updateSite(newSite)
            console.log(data)
            setShowAlert(true)
        } catch { }
        setIsSaving(false)
    }

    useEffect(() => {
        getDetail();
    }, [])

    if (loading) {
        return (
            <PageWrapper title={t('siteSetting')}>
                <Loader />
            </PageWrapper>
        )
    }

    return (
        <PageWrapper title={t('siteSetting')}>
            <Box p={1}>
                <Paper>
                    <Box py={1}>
                        <Container maxWidth="xs">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField label={t('PICName')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={picName ?? ''} onChange={(e) => setPicName(e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('PICPhone')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={picPhone ?? ''} onChange={(e) => setPicPhone(e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('address')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={address ?? ''} multiline rows={3} onChange={(e) => setAddress(e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup row value={locationType} onChange={(e) => setLocationType(e.target.value)}>
                                        <FormControlLabel label={t('radius')} value={'1'} control={<Radio />} xs={9} />
                                        <FormControlLabel label={t('geofence')} value={'2'} control={<Radio />} />
                                    </RadioGroup>
                                </Grid>
                                {locationType == '1' && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField label={t('latitude')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={point?.lat ?? ''} disabled />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('longitude')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={point?.lng ?? ''} disabled />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField label={t('radius')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={radius} onChange={(e) => setRadius(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={8} />
                                    </>
                                )}

                                {locationType == '2' && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField label={t('geofenceCoordinate')} disabled fullWidth InputLabelProps={{ shrink: true }} variant="outlined" multiline rows={3} value={geofencePoints.length > 0 ? JSON.stringify(geofencePoints) : ''} onChange={(e) => setRadius(e.target.value)} />
                                        </Grid>
                                    </>
                                )}
                                <MapsContainer
                                    showMarker={locationType == '1'}
                                    markerPos={point}
                                    onMarkerPositionChanged={onMarkerPositionChanged}
                                    polygonPoints={locationType == '1' ? [] : geofencePoints}
                                    onPolygonChanged={onGeofenceChanged} />
                                <Grid item xs={12}>
                                    <Button variant="contained" size="large" fullWidth color="primary" onClick={save} disabled={isSaving}>{isSaving ? <img className="btn-loader" src={imgLoading} alt="" /> : t("save")}</Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Paper>
            </Box>
            <Alert isOpen={showAlert} onClose={() => setShowAlert(false)} title={t('success')} message={t('saveSiteSuccess')} actions={[
                { label: t('ok'), handler: () => setShowAlert(false) }
            ]} />
        </PageWrapper>
    )
}

export default SiteSettingPage