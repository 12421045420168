import axios from 'axios'

export const authenticate = async (username, password) => {
    return axios.post(window.Configuration['API_URL'] + "/api/Auth/Login", {
        userName: username,
        password: password
    });
}

export const changePassword = async (oldPassword, newPassword) => {
    return axios.post(window.Configuration['API_URL'] + "/api/Auth/ChangePassword", {
        oldPassword: oldPassword,
        newPassword: newPassword
    }); 
}

export const changeNewUserPassword = async (oldPassword, newPassword, access_token) => {
    return axios.post(window.Configuration['API_URL'] + "/api/Auth/ChangePassword", {
        oldPassword: oldPassword,
        newPassword: newPassword
    }, {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    }); 
}

export const sendForgetPassword = async (usernameOrEmail) => {
    return axios.post(window.Configuration['API_URL'] + "/api/Auth/SendTokenForgetPassword", {
        userNameOrEmail: usernameOrEmail
    });
}

export const verifyForgetPassword = async (username, token, newPassword) => {
    return axios.post(window.Configuration['API_URL'] + "/api/Auth/VerifyTokenForgetPassword", {
        userName: username,
        token: token,
        newPassword: newPassword
    });
}