import React, { useState } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/bat-logo-white.svg';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import { changeNewUserPassword } from "../../services/authorizationService";
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Alert from '../../components/Alert';

import imgLoading from '../../assets/images/loading.gif'
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import imgBuilding from '../../assets/images/building-sign.svg';
import truckSign from '../../assets/images/truck-and-sign.svg'

const NewUserPasswordPage = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { access_token, access_token_exp, current_user, password } = location?.state ?? {};

    const [errors, setErrors] = useState({})
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [showForgotPasswordSuccessAlert, setShowForgotPasswordSuccessAlert] = useState(false)

    // toggle password
    const [valuesPassword, setValuesPassword] = React.useState({
        password: '',
        showPassword: false,
    });

    // toggle confirm password
    const [valueConfirmPassword, setValueConfirmPassword] = React.useState({
        confirmPassword: '',
        showConfirmPassword: false,
    });

    // date
    const now = new Date();
    const days = [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")];
    const months = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];

    const day = days[now.getDay()];
    const month = months[now.getMonth()];


    const handleSubmit = async () => {
        if (validate()) {
            setLoading(true)
            try {
                const { data } = await changeNewUserPassword(password, newPassword, access_token);
                if (data.success) {
                    localStorage.setItem("access_token", access_token)
                    localStorage.setItem("access_token_exp", access_token_exp)
                    localStorage.setItem("current_user", JSON.stringify(current_user))
                    setShowForgotPasswordSuccessAlert(true)
                } else {
                    setErrors({ form: data.payload })
                }
            } catch { }
            setLoading(false)
        }
    }

    const validate = () => {
        const error = {};
        if (newPassword.trim() === '') {
            error.newPassword = t("requiredField");
        }

        if (confirmPassword.trim() === '') {
            error.confirmPassword = t("requiredField");
        }

        if (Object.keys(error).length > 0) {
            setErrors(error);
            return false;
        }

        if (newPassword !== confirmPassword) {
            error.confirmPassword = t('newAndConfirmPasswordMustEqual');
        }

        setErrors(error)

        return Object.keys(error).length === 0
    }

    const handleOnClosePopup = () => {
        setShowForgotPasswordSuccessAlert(false);
        history.replace('/');
    }

    // toggle password
    const handleClickShowPassword = () => {
        setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // toggle confirm password
    const handleClickShowConfirmPassword = () => {
        setValueConfirmPassword({ ...valueConfirmPassword, showConfirmPassword: !valueConfirmPassword.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

     const [focus, setFocus] = useState(false);

    if (!access_token || !access_token_exp || !current_user) return <Redirect to="/not_found" />

    return (
        <div className="login-page-wrap">

            <div className="login-page-wrap__top">
                <div className="bg-gradient-20"></div>
            </div>

            <Container maxWidth="xs">
                <Grid container spacing={2} alignItems="flex-end" className="py-3">
                    <Grid item xs={6}>
                        <img src={logo} alt="bat logo" />
                    </Grid>
                    <Grid item xs={6}>
                        <p className="caption text-right color-white">{day}</p>
                        <p className="caption text-right color-white">{`${now.getDate()} ${month} ${now.getFullYear()}`}</p>
                        <p className="caption text-right color-white">{"Version " + window.Configuration['VERSION']}</p>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="xs" className={`login-page-wrap__content ${focus ? 'fullscreen' : '' }`}>
                <div className="login-container">
                    <div className="login-form">
                        <Grid spacing={2} container direction="column">

                            {/* title */}
                            <Grid item xs={12} container justify="center" alignItems="center" direction="column">
                                <Typography variant="h6" className="font-bold mt-4 pb-2 login-page-wrap__title">{t("resetPassword")}</Typography>
                            </Grid>

                            {/* password */}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" error={errors && errors?.newPassword} fullWidth>
                                    <InputLabel htmlFor="password-outlined">{t('newPassword')}</InputLabel>
                                    <OutlinedInput
                                        id="password-outlined"
                                        type={valuesPassword.showPassword ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        onFocus={() => setFocus(true)}
                                        onBlur={() => setFocus(false)}
                                        label={t('newPassword')}
                                        error={Object.keys(errors).length > 0}
                                        fullWidth
                                        className="input-style"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText>{errors.newPassword}</FormHelperText>
                                </FormControl>
                            </Grid>

                            {/* confirm password */}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" error={errors && errors?.confirmPassword} fullWidth>
                                    <InputLabel htmlFor="password-outlined">{t('confirmPassword')}</InputLabel>
                                    <OutlinedInput
                                        id="password-outlined"
                                        type={valueConfirmPassword.showConfirmPassword ? 'text' : 'password'}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        onFocus={() => setFocus(true)}
                                        onBlur={() => setFocus(false)}
                                        label={t('confirmPassword')}
                                        error={Object.keys(errors).length > 0}
                                        fullWidth
                                        className="input-style"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password confirmation visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    {valueConfirmPassword.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText>{errors.confirmPassword}</FormHelperText>
                                </FormControl>
                            </Grid>

                            {/* form error */}
                            {errors.form &&
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="text-red text-center">{errors.form}</Typography>
                                </Grid>
                            }

                            {/* submit button */}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                    fullWidth
                                    className="btn-xl btn-primary"
                                >
                                    {loading ? <img className="btn-loader" src={imgLoading} alt="loader" /> : t("reset")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {/* footer */}
                <div className="login-page-wrap__illustration-wrap mt-auto">
                    <div className="d-flex align-items-end">
                        <div className="d-flex login-page-wrap__illustration-col-left">
                            <img src={imgBuilding} alt="bat logo" />
                        </div>
                        <div className="d-flex login-page-wrap__illustration-col-right">
                            <img src={truckSign} alt="bat logo" />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="bg-gradient-20 w-100"></div>
                    </div>
                </div>


            </Container>

            <Alert isOpen={showForgotPasswordSuccessAlert} onClose={handleOnClosePopup} title={t('success')} message={t('resetPasswordSuccess')} actions={[
                { label: t('ok'), handler: handleOnClosePopup }
            ]} />
        </div>
    )
}

export default NewUserPasswordPage;