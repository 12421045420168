import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import './scss/app.scss'
import axios from 'axios';
import pubsub from './utilities/pub-sub'
import { ToastContainer, toast } from 'react-toastify';

axios.interceptors.request.use(async (config) => {
    const access_token = localStorage.getItem('access_token');
    if (access_token !== null) config.headers['Authorization'] = `Bearer ${access_token}`;
    if (!config.timeout || config.timeout === 0) config.timeout = window.Configuration.TIMEOUT * 1000;
    return config;
})

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error?.response?.status === 401) {
            pubsub.publish("UNAUTHORIZE", error);
            return Promise.reject({ ...error })
        }

        pubsub.publish("NETWORKERROR", error);
        return Promise.reject({ ...error })
    }
)

function App() {
    const onUnauthorize = () => {
        localStorage.removeItem("access_token");
        window.location.reload()
    }

    const onNetWorkError = () => {
        toast.error("Failed to connect to server. \nPlease check your internet connection and try again.", {
            toastId: 'toast-network-error-request'
        })
    }

    const onScroll = (e) => {
        if (document.activeElement.tagName.toLowerCase() === 'input')
            document.activeElement.blur();
    }

    useEffect(() => {
        const unsub = pubsub.subscribe("UNAUTHORIZE", onUnauthorize)
        const unnetsub = pubsub.subscribe("NETWORKERROR", onNetWorkError)
        // window.addEventListener('scroll', onScroll, { passive: true });
        return () => {
            unsub.remove();
            unnetsub.remove();
            // window.removeEventListener('scroll', onScroll);
        }
    }, [])
    return (
        <BrowserRouter baseUrl={"/"}>
            <Routes />
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
