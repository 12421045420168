import { Box, Button, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import PageWrapper from '../../components/PageWrapper';
import { getStuffingItems, getStuffingResults, saveStuffingResults } from '../../services/jobOrderService'
import { useQuery } from '../../utilities/urlUtility';

const StuffingPage = props => {
    const query = useQuery();
    const history = useHistory();
    const cargoId = query.get('id')
    const { t } = useTranslation()
    const [labelItems, setLabelItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [showSubmitConfirm, setShowSubmitConfirm] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const onChange = (value, id) => {
        setValues({ ...values, [id]: value })
    }

    useEffect(() => {
        const getAllStuffingItems = async () => {
            try {
                const { data } = await getStuffingItems();
                if (data?.success) {
                    setLabelItems((data?.payload ?? []).sort((a, b) => a.order - b.order))
                }
            } catch { }
        }

        const loadExistingStuffing = async () => {
            try {
                const { data: { payload, success } } = await getStuffingResults(cargoId);
                if (success) {
                    const existingValues = {};
                    for (let i = 0; i < payload.length; i++) {
                        existingValues[payload[i].jobOrderStuffingId] = payload[i].timeString
                    }
                    setValues(existingValues);
                }
            } catch { }
        }

        const getAllData = async () => {
            setLoading(true)
            await Promise.all([getAllStuffingItems(), loadExistingStuffing()])
            setLoading(false)
        }

        getAllData();
    }, [cargoId])

    const submit = async () => {
        setShowSubmitConfirm(false)
        setIsSaving(true)
        try {
            const params = {
                jobOrderCargoId: cargoId,
                times: Object.keys(values).map(id => ({ jobOrderStuffingId: id, time: values[id] }))
            }
            const { data } = await saveStuffingResults(params);
            if (data?.success) {
                setShowSuccess(true)
            } else {
                alert((data?.errors ?? []).join(", "))
            }
        } catch { }
        setIsSaving(false)
    }

    const handleSuccessAlertClose = () => {
        history.goBack();
    }

    return (
        <PageWrapper title={t('loading')}>
            { loading && <Loader />}

            <Box p={1}>
                <Paper className="mb-2">
                    <Box p={2}>
                        {
                            labelItems.map(item => (
                                <Box mb={3} key={item.id}>
                                    <TextField
                                        id="time"
                                        label={item.name}
                                        type="time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{
                                            width: '100%'
                                        }}
                                        value={values[item.id] ?? ''}
                                        onChange={(e) => onChange(e.target.value, item.id)}
                                    />
                                </Box>
                            ))}

                    </Box>

                </Paper>

                <Button variant="contained" size="large" fullWidth color="primary" onClick={() => setShowSubmitConfirm(true)} disabled={(isSaving || loading)}>{t('save')}</Button>
            </Box>

            <Alert
                message={t('areYouSure')}
                isOpen={showSubmitConfirm}
                onClose={() => setShowSubmitConfirm(false)}
                actions={[
                    { label: t('no'), handler: () => setShowSubmitConfirm(false) },
                    { label: t('yes'), handler: submit }
                ]} />
            <Alert
                isOpen={showSuccess}
                onClose={handleSuccessAlertClose}
                title={t("success")}
                message={t("dataSaved")}
                actions={[
                    {
                        label: t("ok"),
                        handler: handleSuccessAlertClose,
                    },
                ]}
            />
        </PageWrapper>
    )
}

export default StuffingPage;