import axios from "axios";
import constants from "../utilities/constants";
import moment from "moment";

export const getTransporterJobOrderList = (page = 1, size = 10) => {
  return getJobOrderList(page, size, null, [constants.jobOrderStatus.scheduled, constants.jobOrderStatus.in_transit]);
  // return getJobOrderList(page, size, null, []);
  
};

export const getSecurityJobOrderList = (page = 1, size = 10) => {
  return getJobOrderList(page, size, null, [
    constants.jobOrderStatus.scheduled,
  ]);
};

export const getReceiverJobOrderList = (page = 1, size = 10) => {
  return getJobOrderList(page, size, null, [
    constants.jobOrderStatus.in_transit,
  ]);
};

export const getJobOrderList = (
  page = 1,
  size = 10,
  jobOrderDate,
  statusList
) => {
  const params = {};
  if (statusList) params.statusList = statusList;
  if (jobOrderDate) params.jobOrderDate = jobOrderDate;
  
  return axios.post(
    `${window.Configuration["API_URL"]}/api/JobOrder/GetJobOrderList?currentPage=${page}&pageSize=${size}`,
    params
  );
};

export const getJobOrderDetail = (id) => {
  return axios.get(`${window.Configuration["API_URL"]}/api/JobOrder/GetJobOrderIncludePOById/${id}`);
};

export const getJobOrderCargoes = (jobOrderId) => {
  return axios.get(`${window.Configuration["API_URL"]}/api/JobOrder/GetJobOrderCargoes/${jobOrderId}`);
};

export const saveJobOrderCargo = (params) => {
  return axios.post(
    `${window.Configuration["API_URL"]}/api/JobOrder/SaveJobOrderCargo`,
    params
  );
};

export const getStuffingItems = () => {
    return axios.get(`${window.Configuration["API_URL"]}/api/JobOrder/GetAllStuffingItems`)
}

export const saveStuffingResults = (params) => {
    return axios.post(`${window.Configuration["API_URL"]}/api/JobOrder/SaveJobOrderStuffing`, params)
}

export const getStuffingResults = (cargoId) => {
    return axios.get(`${window.Configuration["API_URL"]}/api/JobOrder/GetJobOrderStuffings/${cargoId}`)
}

export const getObdByJobOrder = (jobOrderId) => {
    return axios.get(`${window.Configuration["API_URL"]}/api/JobOrder/GetJobOrderOBDByJobOrder/${jobOrderId}`)
}

export const getObdByCargo = (jobOrderCargoId) => {
    return axios.get(`${window.Configuration["API_URL"]}/api/JobOrder/GetJobOrderOBDByCargo/${jobOrderCargoId}`)
}

export const approveCargoLoad = (jobOrderCargoId, notes) => {
    return axios.post(`${window.Configuration["API_URL"]}/api/JobOrder/ApproveCargoLoad`, {
        jobOrderCargoId: jobOrderCargoId,
        notes: notes
    })
}

export const markCargoAsReceived = (cargoId, obds, photos) => {
    return axios.post(`${window.Configuration["API_URL"]}/api/JobOrder/SaveDeliveryConfirm`, {
        jobOrderCargoId: cargoId,
        jobOrderObds: obds,
        jobOrderCargoDeliverySite: photos
    })
}

export const getDeliveryDocuments = (jobOrderCargo, site) => {
    return axios.post(`${window.Configuration["API_URL"]}/api/JobOrder/GetListCargoDocument`, {
        jobOrderCargoId: jobOrderCargo,
        siteId: site
    })
}