import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AccordionItem from './AccordionItem';

const JobOrderDetail = ({ jobOrder }) => {
    const { t } = useTranslation();
    return (
        <Box p={1}>
            <Paper square className="card card-gradient mb-2">

                <Box p={2} mb={1}>
                    <Grid container>
                        <Grid container>
                            <Grid item xs={6}><Typography variant="body1" gutterBottom color="primary"><strong>{t('documentNo')}</strong></Typography></Grid>
                            <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">#{jobOrder?.joNumber}</Typography></Grid>
                        </Grid>

                        {
                            jobOrder.transporter && (
                                <Grid container>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="primary"><strong>{t('transporter')}</strong></Typography></Grid>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.transporter?.companyName}</Typography></Grid>
                                </Grid>)
                        }

                        {
                            jobOrder.siteOrigin && (
                                <Grid container>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="primary"><strong>{t('origin')}</strong></Typography></Grid>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.siteOrigin?.name}</Typography></Grid>
                                </Grid>
                            )

                        }

                        {
                            jobOrder.siteDestination && (
                                <Grid container>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="primary"><strong>{t('destination')}</strong></Typography></Grid>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.siteDestination?.name}</Typography></Grid>
                                </Grid>
                            )

                        }

                        {
                            jobOrder.slotTimeStart && jobOrder.slotTimeEnd && (
                                <Grid container>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="primary"><strong>{t('slotTime')}</strong></Typography></Grid>
                                    <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder.slotTimeStart} - {jobOrder.slotTimeEnd}</Typography></Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Paper>

            {
                (jobOrder.jobOrderPOs ?? []).map(po => (
                    <AccordionItem
                        key={po.id}
                        title={po.poNumber}
                        subtitle={po.siteDestinationName}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {po.jobOrderPOLoads.map((load, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <Grid item xs={10}>
                                                    <Typography variant="body2" color="primary"><b>{load.sku}</b></Typography>
                                                    <Typography variant="body2">{load.brandName}</Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="right">{load.totalCase} cases{load.totalPack > 0 ? " (+" + load.totalPack + " packs)" : ""}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </AccordionItem>
                ))
            }
        </Box>
    )
}

export default JobOrderDetail