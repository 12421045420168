import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import notFound from '../assets/images/page-not-found.svg'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  img: {
    width: 200,
    height: 200,
    marginBlockStart: theme.spacing(1)
  }
  
}));

const Error404Page = props => {

      const classes = useStyles();

    const { t } = useTranslation();
    return (
        
            <Paper>
                <Box p={2} height="100vh">
                    <Grid container
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ height: 'calc(100vh - 56px)' }}>
                        <Box mb={1}>
                                <img className={classes.img} src={notFound} alt="Not Found" />

                        </Box>
                        <Box >
                              <Typography variant="body1">{t('pageNotFound')}</Typography>
                        </Box>

                    </Grid> 
                </Box>
        </Paper>
        
    )
}

export default Error404Page;