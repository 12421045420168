import { AppBar, Fab, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../components/TabPanel';
import { useQuery } from '../../utilities/urlUtility';
import JobOrderDetail from '../../components/JobOrderDetail';
import VehicleList from '../../components/VehicleList';
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { getJobOrderDetail, getJobOrderCargoes } from '../../services/jobOrderService';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { CommuteOutlined, LayersOutlined, ListAltOutlined, LocalCafeOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import constants from '../../utilities/constants';


const JobOrderDetailPage = props => {
    const query = useQuery();
    const history = useHistory();
    const { t } = useTranslation();
    const id = query.get('id');

    const [selectedTab, setSelectedTab] = useState(parseInt(localStorage.getItem('selected_tab') ?? '0'));
    const [loading, setLoading] = useState(true);
    const [jobOrder, setJobOrder] = useState({});
    const [cargoes, setCargoes] = useState([]);

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
        localStorage.setItem('selected_tab', val)
    }

    const redirectToStuffing = (id) => {
        history.push('/transporter/stuffing?id=' + id);
    }

    const redirectToVehicleLoad = (id) => {
        const cargo = cargoes.filter(c => c.id === id)[0];
        history.push('/transporter/item_loads?id=' + id, { cargo: cargo });
    }

    const redirectToVehicleForm = (id) => {
        const lastVehicle = cargoes.length > 0 ? cargoes[cargoes.length - 1] : null
        if (id) {
            const cargo = cargoes.filter(c => c.id === id)[0];
            history.push('/transporter/vehicle_form?id=' + id, { jobOrder: jobOrder, lastVehicle: lastVehicle, cargo: cargo })
        } else {
            history.push('/transporter/vehicle_form', { jobOrder: jobOrder, lastVehicle: lastVehicle })
        }
    }

    const loadDetail = async () => {
        try {
            const { data } = await getJobOrderDetail(id);
            if (data?.success) {
                console.log('loadDetail', data.payload)
                setJobOrder(data?.payload);
            }
        } catch { }
    }

    const loadCargoes = async () => {
        try {
            const { data } = await getJobOrderCargoes(id);
            if (data?.success) {
                console.log('loadCargoes', data.payload)
                setCargoes(data?.payload);
            }
        } catch { }
    }

    useEffect(() => {
        const getAllData = async () => {
            setLoading(true);
            await Promise.all([loadDetail(), loadCargoes()]);
            setLoading(false);
        }

        getAllData()
    }, [])

    const useStyles = makeStyles((theme) => ({

        fab: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },

    }));
    const classes = useStyles();

    return (
        <PageWrapper title={t('jobOrder')}>
            <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    aria-label="icon label tabs example">
                    <Tab icon={<ListAltOutlined />} label={t('detail')} />
                    <Tab icon={<CommuteOutlined />} label={t('vehicle')} />
                    <Tab icon={<LayersOutlined />} label={t('loads')} />
                    <Tab icon={<LocalCafeOutlined />} label={t('loading')} />
                </Tabs>
            </AppBar>
            {loading ?
                (<Loader />) :
                (<>
                    <TabPanel value={selectedTab} index={0}>
                        <JobOrderDetail jobOrder={jobOrder} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <VehicleList cargoes={cargoes} onSelected={redirectToVehicleForm} />
                        <Fab className={`btn-fab ${classes.fab}`} color="primary" aria-label="add" onClick={() => redirectToVehicleForm()}>
                            <AddIcon />
                        </Fab>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <VehicleList cargoes={cargoes.filter(val => val.isPickupTruck)} onSelected={redirectToVehicleLoad} page="load_approval_transporter" />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        <VehicleList cargoes={(cargoes ?? []).filter(x => x.isPickupTruck && x.transportationType == constants.transportationType.truck)} onSelected={redirectToStuffing} />
                    </TabPanel>
                </>)
            }
        </PageWrapper >
    )
}

export default JobOrderDetailPage;