import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'

const Alert = ({ isOpen, onClose, title, message, actions, children }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true}
maxWidth = {'md'}>
            {title &&
                <DialogTitle>
                    <span color="primary">{title}</span>
                </DialogTitle>
            }

            {(message || children) && (
                <DialogContent >
                    {message &&
                        <DialogContentText>{message}</DialogContentText>
                    }

                    {children}
                </DialogContent>
            )}
            {(actions && actions.length > 0) && (
                <DialogActions>
                    {actions.map((act, index) => (
                        <Button onClick={act.handler} color="primary" key={index}>{act.label}</Button>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    )
}

Alert.defaultProps = {
    isOpen: false,
    title: null,
    message: null,
    actions: null
}

export default Alert;