import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import notFound from '../assets/images/not-found.svg'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  img: {
    width: 100,
    height: 100,
    marginBlockStart: theme.spacing(1)
  }
  
}));

const NoDataComponent = props => {

      const classes = useStyles();

    const { t } = useTranslation();
    return (
        
            <Box p={1}>
              <Paper>
                <Box p={2}>
                    <Grid container
                              spacing={1}
                              direction="column"
                              alignItems="center"
                              justify="center"
                              >
                              <Box mb={1}>
                                      <img className={classes.img} src={notFound} alt="Not Found" />

                              </Box>
                              <Box mb={2}>
                                                                  <Typography variant="body1">{t('noData')}</Typography>

                              </Box>

                          </Grid> 
                      </Box>
              </Paper>
            </Box>
        
    )
}

export default NoDataComponent;