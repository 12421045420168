import React, { useState, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker, DrawingManager, Polygon } from '@react-google-maps/api';
import { Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const MapsContainer = ({ showMarker, markerPos, onMarkerPositionChanged, polygonPoints, onPolygonChanged }) => {
    const { t } = useTranslation()
    const [initialPosition, setInitialPosition] = useState(null)
    const [overlay, setOverlay] = useState(null)
    const [polygonCenter, setPolygonCenter] = useState(null)

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setInitialPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        })
    }, [])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: window.Configuration["GMAPS_KEY"],
        libraries: ["drawing"]
    })

    const [map, setMap] = React.useState(null)
    //const [drawer, setDrawer] = React.useState(null)

    const onMapsClicked = (e) => {
        if (showMarker)
            onMarkerPositionChanged(e.latLng.lat(), e.latLng.lng())
    }

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    /*
    const onDrawerLoad = React.useCallback(function callback(drawer) {
        console.log(drawer)
        setDrawer(drawer)
    }, [])
    */

    useEffect(() => {
        if (overlay) {
            if (showMarker) {
                overlay.setMap(null)
            } else {
                overlay.setMap(map)
            }
        }
    }, [showMarker])

    useEffect(() => {
        if ((polygonPoints ?? []).length > 0) {
            const lats = polygonPoints.map(x => x.lat);
            const latH = Math.max(...lats)
            const latL = Math.min(...lats)
            const lons = polygonPoints.map(x => x.lng);
            const lonH = Math.max(...lons)
            const lonL = Math.min(...lons)
            const centerLat = latL + ((latH - latL) / 2)
            const centerLng = lonL + ((lonH - lonL) / 2)
            setPolygonCenter({
                lat: centerLat,
                lng: centerLng
            })
            onMarkerPositionChanged(centerLat, centerLng)

            if (map) {
                const bounds = new window.google.maps.LatLngBounds({ lat: latL, lng: lonL }, { lat: latH, lng: lonH })
                map.fitBounds(bounds)
            }
        }
    }, [polygonPoints, map])

    const onDrawingComplete = (polygon) => {
        const points = polygon.getPath().getArray().map(x => ({ lat: x.lat(), lng: x.lng() }))
        onPolygonChanged(points)
    }

    const onOverlayComplete = (e) => {
        setOverlay(e.overlay)
    }

    const clearGeofence = () => {
        overlay?.setMap(null)
        setOverlay(null)
        onPolygonChanged([])
    }

    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={(showMarker ? markerPos : polygonCenter) ?? initialPosition}
                zoom={16}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={onMapsClicked}
                options={{
                    streetViewControl: false,
                    mapTypeControl: false
                }}
                mapTypeId="ROADMAP"
                clickableIcons={false}
            >
                <>
                    {showMarker && markerPos && <Marker position={markerPos} />}
                    {!showMarker &&
                        <>
                            <DrawingManager drawingMode={(polygonPoints ?? []).length == 0 ? "polygon" : null}
                                onPolygonComplete={onDrawingComplete}
                                onOverlayComplete={onOverlayComplete}
                                options={{
                                    drawingControl: (polygonPoints ?? []).length == 0,
                                    drawingControlOptions: {
                                        drawingModes: ["polygon"]
                                    },
                                    polygonOptions: {
                                        paths: (polygonPoints ?? []).map(point => new window.google.maps.LatLng(point.lat, point.lng))
                                    }
                                }} />
                            {!overlay && (polygonPoints ?? []).length > 0 &&
                                <Polygon paths={polygonPoints} />
                            }

                        </>
                    }
                </>
            </GoogleMap>
            {!showMarker && (polygonPoints ?? []).length > 0 && <>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <Button variant="contained" size="large" fullWidth color="primary" onClick={clearGeofence}>{t('clear')}</Button>
                </Grid>
            </>}
        </>
    ) : <></>
}

MapsContainer.defaultProps = {
    showMarker: true
}

export default MapsContainer