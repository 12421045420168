import React, { useState } from "react";
import { Formik } from 'formik'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { authenticate, sendForgetPassword } from "../../services/authorizationService";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/bat-logo-white.svg';
import Link from '@material-ui/core/Link';
import Alert from "../../components/Alert";
import { Box, Paper, TextField } from "@material-ui/core";
import imgLoading from '../../assets/images/loading.gif'
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import imgBuilding from '../../assets/images/building-sign.svg';
import truckSign from '../../assets/images/truck-and-sign.svg'


const LoginPage = props => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [formError, setFormError] = useState(null);
    const history = useHistory();
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [forgotPassUsername, setForgotPassUsername] = useState('')
    const [resetPasswordError, setResetPasswordError] = useState({})
    const [showForgotPasswordSuccessAlert, setShowForgotPasswordSuccessAlert] = useState(false)
    const [valuesPassword, setValuesPassword] = React.useState({
        password: '',
        showPassword: false,
    });
    const now = new Date();
    const days = [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")];
    const months = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];

    const day = days[now.getDay()];
    const month = months[now.getMonth()];

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [focus, setFocus] = useState(false);



    const authorize = async (username, password) => {
        setUsername(username);
        setPassword(password);
        setLoading(true);
        try {
            var { data } = await authenticate(username, password)
            if (data) {
                if (data.success) {
                    if (data.payload.isNewAccount) {
                        history.replace('/new_user', {
                            access_token: data.payload.accessToken,
                            access_token_exp: data.payload.expiredUtc,
                            current_user: data.payload,
                            password: password
                        })
                    } else {
                        localStorage.setItem("access_token", data.payload.accessToken)
                        localStorage.setItem("access_token_exp", data.payload.expiredUtc)
                        localStorage.setItem("current_user", JSON.stringify(data.payload))
                        history.replace('/')
                    }
                } else {
                    setFormError(data.errors.join("<br />"))
                }
            } else {
                setFormError('Login Failed');
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const onCloseForgotPassword = () => {
        setForgotPassUsername('');
        setResetPasswordError({})
        setShowForgotPassword(false);
    }

    const submitForgotPassword = async () => {
        try {
            if (forgotPassUsername.trim() === '') {
                setResetPasswordError({
                    username: t('requiredField')
                })
                return;
            }
            const { data } = await sendForgetPassword(forgotPassUsername);
            if (data.success) {
                setShowForgotPasswordSuccessAlert(true);
                onCloseForgotPassword();
            } else {
                setResetPasswordError({ username: data.errors.join(', ') })
            }
        } catch { }
    }

    const handleClickShowPassword = () => {
        setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword });
    };


    return (
        <div className="login-page-wrap">
            <div className="login-page-wrap__top">
                <div className="bg-gradient-20"></div>
            </div>
            <Container maxWidth="xs">
                <Grid container spacing={2} container alignItems="flex-end" className="py-3">
                    <Grid item xs={6}>
                        <img className="login-page-wrap__logo" src={logo} alt="bat logo" />
                    </Grid>
                    <Grid item xs={6}>
                        <p className="caption text-right color-white">{day}</p>
                        <p className="caption text-right color-white">{`${now.getDate()} ${month} ${now.getFullYear()}`}</p>
                        <p className="caption text-right color-white">{"Version " + window.Configuration['VERSION']}</p>
                    </Grid>
                </Grid>
            </Container>
            <Formik
                initialValues={{ username: username, password: password }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    setFormError("");

                    let errors = {};

                    if (values.username === "") {
                        errors.username = t("requiredField");
                    }

                    if (values.password === "") {
                        errors.password = t("requiredField");
                    }

                    return errors;
                }}
                onSubmit={async (values) => {
                    authorize(values.username, values.password);
                }}
            >
                {({ handleChange, handleSubmit, values, touched, errors, isSubmitting }) => (

                    <Container maxWidth="xs" className={`login-page-wrap__content ${focus ? 'fullscreen' : '' }`}>
                        <div className="login-container">
                            <div className="login-form">
                                <Grid spacing={2} container direction="column">

                                    {/* title */}
                                    <Grid item xs={12} container justify="center" alignItems="center" direction="column">
                                        <Typography variant="h6" className="font-bold mt-4 pb-2 login-page-wrap__title">{t("loginTitle")}</Typography>
                                    </Grid>


                                    {/* username */}
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" error={errors && errors?.username} fullWidth>
                                            <InputLabel htmlFor="username-outlined">{t('username')}</InputLabel>
                                            <OutlinedInput id="username-outlined"
                                                value={values.username}
                                                onChange={handleChange('username')}
                                                label={t('username')}
                                                error={Object.keys(errors).length > 0}
                                                fullWidth
                                                className="input-style"
                                                onFocus={() => setFocus(true)}
                                                onBlur={() => setFocus(false)}
                                            />
                                            <FormHelperText>{errors.username}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {/* password */}
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" error={errors && errors?.password} fullWidth>
                                            <InputLabel htmlFor="password-outlined">{t('password')}</InputLabel>
                                            <OutlinedInput
                                                id="password-outlined"
                                                type={valuesPassword.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange('password')}
                                                onFocus={() => setFocus(true)}
                                                onBlur={() => setFocus(false)}
                                                label={t('password')}
                                                error={Object.keys(errors).length > 0}
                                                className="input-style"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText>{errors.password}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {formError &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className="text-red text-center">{formError}</Typography>
                                        </Grid>
                                    }


                                    {/* forgot password */}
                                    <Grid item xs={12} container justify="flex-end">
                                        <Link href="#" className="caption" onClick={() => setShowForgotPassword(true)}>
                                            {t('forgotPassword')}
                                        </Link>
                                    </Grid>

                                    {/* submit button */}
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            disabled={isSubmitting}
                                            onClick={handleSubmit}
                                            fullWidth
                                            className="btn-xl btn-primary login-page-wrap__btn"
                                        >

                                            {loading ? <img className="btn-loader" src={imgLoading} alt="" /> : t("login")}
                                        </Button>
                                    </Grid>


                                </Grid>
                            </div>
                        </div>
                        <div className="login-page-wrap__illustration-wrap mt-auto">
                            <div className="d-flex align-items-end">
                                <div className="d-flex login-page-wrap__illustration-col-left">
                                    <img src={imgBuilding} alt="bat logo" />
                                </div>
                                <div className="d-flex login-page-wrap__illustration-col-right">
                                    <img src={truckSign} alt="bat logo" />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="bg-gradient-20 w-100"></div>
                            </div>
                        </div>
                    </Container>
                )}
            </Formik>
            <Alert isOpen={showForgotPassword} onClose={onCloseForgotPassword} title={t('forgotPassword')} message={t('forgotPasswordMessage')} actions={[
                { label: t('cancel'), handler: onCloseForgotPassword },
                { label: t('submit'), handler: submitForgotPassword }
            ]}>
                <Box my={2}>
                    <TextField label={t('usernameOrEmail')} color="primary" fullWidth value={forgotPassUsername} onChange={(e) => setForgotPassUsername(e.target.value)} autoFocus />

                    {resetPasswordError.username &&
                        <Box mt={1}><Typography variant="body2" className="text-red">{resetPasswordError.username}</Typography></Box>
                    }
                </Box>
            </Alert>
            <Alert isOpen={showForgotPasswordSuccessAlert} onClose={() => setShowForgotPasswordSuccessAlert(false)} title={t('success')} message={t('forgotPasswordSuccess')} actions={[
                { label: t('ok'), handler: () => setShowForgotPasswordSuccessAlert(false) }
            ]} />
        </div>
    )
}

export default LoginPage;