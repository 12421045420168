import React, { useEffect, useMemo, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
const placeService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    }
}));

const POISearchBox = ({ sites, label, point, onPointChanged, disabled }) => {
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);
    const [mapsResults, setMapResults] = useState([])
    const [filteredSites, setFilteredSites] = useState([])
    const [initial, setInitial] = useState(true)

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${window.Configuration["GMAPS_KEY"]}&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    const getDetail = useMemo(
        () =>
            throttle((request, callback) => {
                placeService.current.getDetails(request, callback);
            }, 200),
        []
    );

    useEffect(() => {
        setValue(point)
        setInputValue(point?.name ?? "")
    }, [point, fetch, getDetail])

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (!placeService.current && window.google) {
            placeService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
        }
        if (!placeService.current) {
            return undefined;
        }

        return () => {
            active = false;
        };
    });

    const onSelected = (option) => {
        if (!option || option.isSite) {
            setValue(option)
            if (onPointChanged) onPointChanged(option)
        } else {
            getDetail({ placeId: option?.placeId ?? "" }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    var val = {
                        ...option,
                        latitude: `${place.geometry.location.lat()}`,
                        longitude: `${place.geometry.location.lng()}`
                    }
                    setValue(val)
                    if (onPointChanged) onPointChanged(val)
                }
            });
        }
    }

    useEffect(() => {
        if (inputValue === "") {
            setMapResults(value ? [value] : []);
            setFilteredSites([...sites])
            // setOptions(value ? [value] : [...sites]);
            return;
        }

        setFilteredSites(sites.filter(x => x.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 || (x.address ?? "").toLowerCase().indexOf(inputValue.toLowerCase()) >= 0))

        if (!autocompleteService.current) {
            return setMapResults(value && value.isSite === false ? [value] : []);;
        }

        fetch({ input: inputValue }, (results) => {
            let newOptions = [];

            if (results) {
                newOptions = [...newOptions, ...results];
            }

            const formattedResult = (results ?? []).map((poi) => ({
                address: poi.structured_formatting.secondary_text,
                name: poi.structured_formatting.main_text,
                placeId: poi.place_id,
                isSite: false
            }))
            setMapResults(formattedResult);
        });
    }, [inputValue, fetch, sites])

    useEffect(() => {
        setOptions([...filteredSites, ...mapsResults]);
    }, [filteredSites, mapsResults])

    return (
        <Autocomplete
            disabled={disabled}
            fullWidth
            getOptionLabel={(option) => typeof option === "string" ? option : option.name}
            options={options}
            value={value}
            getOptionSelected={(option, value) => {
                if (typeof option === "string") {
                    return option == value
                } else {
                    return option.placeId == value.placeId
                }
            }}
            onChange={(event, newValue) => {
                onSelected(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    multiline
                    fullWidth
                />
            )}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            {option.isSite ?
                                <HomeWorkIcon className={classes.icon} />
                                :
                                <LocationOnIcon className={classes.icon} />
                            }
                        </Grid>
                        <Grid item xs>
                            <span>{option.name}</span>
                            <Typography variant="body2" color="textSecondary">
                                {option.address}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

POISearchBox.defaultProps = {
    sites: [],
    label: "",
    disabled: false
}

export default POISearchBox;
