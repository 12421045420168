import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Alert from "./Alert";
import { changePassword } from "../services/authorizationService";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
    HomeOutlined,
    VpnKeyOutlined,
    ApartmentOutlined
} from "@material-ui/icons";

import imgBuilding from '../assets/images/building-sign.svg';
import truckSign from '../assets/images/truck-and-sign.svg'
import constants from "../utilities/constants";

const PageWrapper = ({ children, title }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const currentUser = JSON.parse(localStorage.getItem("current_user"));
    const isSiteUser = (currentUser?.roleKey ?? []).includes(constants.allowedRole.receiver);
    const [showLogoutAlert, setShowLogoutAlert] = useState(false);
    const [showChangePasswordAlert, setShowChangePasswordAlert] = useState(false);
    const [
        showChangePasswordSuccessAlert,
        setShowChangePasswordSuccessAlert,
    ] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [changePasswordError, setChangePasswordError] = useState({});

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const logout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("access_token_exp");
        history.push("/login");
    };

    const openJobOrder = () => {
        history.push("/job_order");
    };

    const openSiteSetting = () => {
        history.push("/receiver/site_setting");
    };

    const onCloseChangePassword = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setShowChangePasswordAlert(false);
        setChangePasswordError({});
    };

    const submitChangePassword = async () => {
        try {
            if (validateChangePassword()) {
                const { data } = await changePassword(oldPassword, newPassword);
                if (data.success) {
                    setShowChangePasswordSuccessAlert(true);
                    onCloseChangePassword();
                } else {
                    setChangePasswordError({ confirmPassword: data.payload });
                }
            }
        } catch { }
    };

    const validateChangePassword = () => {
        const errors = {};
        if (oldPassword.trim() === "") {
            errors.oldPassword = t("requiredField");
        }
        if (newPassword.trim() === "") {
            errors.newPassword = t("requiredField");
        }
        if (confirmPassword.trim() === "") {
            errors.confirmPassword = t("requiredField");
        }
        if (Object.keys(errors).length > 0) {
            setChangePasswordError(errors);
            return false;
        }
        if (newPassword.trim() !== confirmPassword.trim()) {
            errors.confirmPassword = t("newAndConfirmPasswordMustEqual");
        }
        setChangePasswordError(errors);
        return Object.keys(errors).length === 0;
    };

    return (

        <Box className="page-wrapper">
            <div className="page-wrapper__top">
                <div className="bg-gradient-20 h-5"></div>
            </div>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    {title && (
                        <Typography variant="h6" noWrap>
                            {title}
                        </Typography>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer variant="persistent" anchor="left" open={open} >
                <div className="bg-gradient-20 h-5"></div>
                <Box
                    component="div"
                    display="flex"
                    className="justify-content-end align-content-center"
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon className="color-cyan" />
                    </IconButton>
                </Box>

                <Divider />
                <List>
                    <ListItem>
                        <ListItemText
                            primary={currentUser.fullName}
                            secondary={`${currentUser.roles.join(", ")}`}
                            primaryTypographyProps={{ variant: "h6" }}
                            secondaryTypographyProps={{ variant: "subtitle" }}
                            className="color-primary drawer__title"
                        />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={openJobOrder}>
                        <ListItemIcon>
                            <HomeOutlined className="color-cyan" />
                        </ListItemIcon>
                        <ListItemText primary={t("home")} />
                    </ListItem>
                    <ListItem button onClick={() => setShowChangePasswordAlert(true)}>
                        <ListItemIcon>
                            <VpnKeyOutlined className="color-cyan" />
                        </ListItemIcon>
                        <ListItemText primary={t("changePassword")} />
                    </ListItem>
                    {isSiteUser &&
                        <ListItem button onClick={openSiteSetting}>
                            <ListItemIcon>
                                <ApartmentOutlined className="color-cyan" />
                            </ListItemIcon>
                            <ListItemText primary={t("siteSetting")} />
                        </ListItem>
                    }
                </List>
                <Divider />
                <List className="mt-auto">
                    <ListItem button onClick={() => setShowLogoutAlert(true)}>
                        <ListItemIcon>
                            <LogoutIcon className="color-pink" />
                        </ListItemIcon>
                        <ListItemText primary={t("logout")} />
                    </ListItem>
                    <ListItem>
                        <ListItemText secondary={"Version " + window.Configuration['VERSION']} style={{textAlign: "center"}} />
                    </ListItem>
                </List>
                <div className="bg-gradient-20 w-100 h-5"></div>


            </Drawer>

            <Toolbar className="mb-2" />

            <Box >{children}</Box>

            <div className="page-wrapper__illustration">
                <div className="d-flex align-items-end">
                    <div className="d-flex page-wrapper__illustration-left">
                        <img src={imgBuilding} alt="bat logo" />
                    </div>
                    <div className="d-flex page-wrapper__illustration-right">
                        <img src={truckSign} alt="bat logo" />
                    </div>
                </div>
                <div className="d-flex page-wrapper__illustration-gradient"><div className="bg-gradient-20 w-100 h-5"></div></div>
            </div>
            <Alert
                isOpen={showLogoutAlert}
                onClose={() => setShowLogoutAlert(false)}
                title={t("logout")}
                message={t("logoutMessage")}
                actions={[
                    { label: t("no"), handler: () => setShowLogoutAlert(false) },
                    { label: t("yes"), handler: () => logout() },
                ]}
            />
            <Alert
                isOpen={showChangePasswordAlert}
                onClose={onCloseChangePassword}
                title={t("changePassword")}
                actions={[
                    { label: t("cancel"), handler: onCloseChangePassword },
                    { label: t("submit"), handler: submitChangePassword },
                ]}
            >
                <Box mb={2}>
                    <TextField
                        label={t("oldPassword")}
                        type="password"
                        fullWidth
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    {changePasswordError.oldPassword && (
                        <Box mt={2} className="text-red"><Typography variant="body2">{changePasswordError.oldPassword}</Typography></Box>
                    )}
                </Box>


                <Box mb={2}>
                    <TextField
                        label={t("newPassword")}
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {changePasswordError.newPassword && (
                        <Box mt={2} className="text-red"><Typography variant="body2">{changePasswordError.newPassword}</Typography></Box>
                    )}
                </Box>


                <Box mb={2}>
                    <TextField
                        label={t("confirmPassword")}
                        type="password"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {changePasswordError.confirmPassword && (
                        <Box mt={2} className="text-red"><Typography variant="body2">{changePasswordError.confirmPassword}</Typography></Box>
                    )}
                </Box>
            </Alert>
            <Alert
                isOpen={showChangePasswordSuccessAlert}
                onClose={() => setShowChangePasswordSuccessAlert(false)}
                title={t("success")}
                message={t("changePasswordSuccess")}
                actions={[
                    {
                        label: t("ok"),
                        handler: () => setShowChangePasswordSuccessAlert(false),
                    },
                ]}
            />
        </Box>
    );
};

export default PageWrapper;
