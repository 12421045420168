import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import TabPanel from '../../components/TabPanel';
import DriverDetail from './components/DriverDetail';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../utilities/urlUtility';
import { getVehicleChecklist, submitVehicleApproval, getVehicleChecklistResult } from '../../services/securityCheckService'
import constants from '../../utilities/constants';
import Alert from '../../components/Alert';

const VehicleCheckPage = props => {
    const { t } = useTranslation();
    const query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [cargo, setCargo] = useState(location.state?.cargo);
    const [jobOrder, setJobOrder] = useState(location.state?.jobOrder);
    const [initialChecklist, setInitialChecklist] = useState([]);
    const [beforeActionChecklist, setBeforeActionChecklist] = useState([]);
    const [finalChecklist, setFinalChecklist] = useState([]);
    const [checklist, setChecklist] = useState([])
    const [loading, setLoading] = useState(true);
    const [submittingData, setSubmittingData] = useState(false);
    const [answers, setAnswers] = useState({});
    const [vehicleChecklistType, setVehicleChecklistType] = useState(1);
    const id = query.get('id');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)

    const getVehicleChecklistItems = async () => {
        try {
            const { data } = await getVehicleChecklist();
            console.log(data)
            if (data && data?.success) {
                setChecklist(data.payload);
            }
        } catch { }
    }

    useEffect(() => {
        setInitialChecklist(checklist.filter(checklist => checklist.category === 0).sort((a, b) => a.order - b.order));
        setBeforeActionChecklist(checklist.filter(checklist => checklist.category === 1).sort((a, b) => a.order - b.order));
        setFinalChecklist(checklist.filter(checklist => checklist.category === 2).sort((a, b) => a.order - b.order));

    }, [checklist])

    useEffect(() => {
        const getAllData = async () => {
            setLoading(true);
            await Promise.all([getVehicleChecklistItems()]);
            setLoading(false);
        }

        getAllData();
    }, []);

    const loadExistingResult = async (cargoId) => {
        const existing = {};

        try {
            const { data } = await getVehicleChecklistResult(cargoId);
            if (data?.payload) {
                data.payload.forEach(val => {
                    existing[val.vehicleChecklistId] = {
                        vehicleChecklistId: val.vehicleChecklistId,
                        isOk: val.isOk,
                        remark: val.remark ?? ''
                    }
                })
            }
        } catch { }

        var answerTemplate = {};
        checklist.forEach((val) => {
            answerTemplate[val.id] = existing[val.id] ? existing[val.id] : {
                vehicleChecklistId: val.id,
                isOk: false,
                remark: ''
            };
        });
        setAnswers(answerTemplate);
    }

    useEffect(() => {
        loadExistingResult(cargo.id);
    }, [cargo, checklist])

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    const updateRemark = (id, val) => {
        const newAnswer = { ...answers };
        newAnswer[id].remark = val;
        setAnswers(newAnswer);
    }

    const updateIsOk = (id, val) => {
        const newAnswer = { ...answers };
        newAnswer[id].isOk = val;
        setAnswers(newAnswer);
    }

    const submitData = async (isApproved) => {
        setSubmittingData(true)
        try {
            const finishedData = {
                jobOrderId: jobOrder.id,
                jobOrderCargoId: id,
                vehicleChecklistType: vehicleChecklistType,
                vehicleChecklistRecords: [],
                isApproved: isApproved
            };
            for (let key in answers) {
                finishedData.vehicleChecklistRecords.push(answers[key]);
            }
            await submitVehicleApproval(finishedData)
            setShowSuccessAlert(true);
        } catch { }
        setSubmittingData(false)
    }

    if (loading) {
        return (<PageWrapper title={t('securityCheck')}>
            <Loader />
        </PageWrapper>)
    }
    return (
        <PageWrapper title={t('securityCheck')}>
            <Box p={1}>
                <Paper className="card card-gradient mb-2">
                    <Box p={2} mb={1} >
                        <Grid container>
                            {/* company name */}
                            <Grid container>
                                <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('transporter')}</strong></Typography></Grid>
                                <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.transporter?.companyName}</Typography></Grid>
                            </Grid>

                            {/* job order date */}
                            <Grid container>
                                <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('date')}</strong></Typography></Grid>
                                <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.jobOrderDate}</Typography></Grid>
                            </Grid>

                            {/* destination */}
                            <Grid container>
                                <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('destination')}</strong></Typography></Grid>
                                <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.siteDestination?.name}</Typography></Grid>
                            </Grid>

                            {/* unit type */}
                            <Grid container>
                                <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('unitType')}</strong></Typography></Grid>
                                <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{cargo?.cargoType?.name}</Typography></Grid>
                            </Grid>

                            {/* if transportation type is truck */}
                            {cargo?.transportationType === constants.transportationType.truck &&
                                <>
                                    {/* license no */}
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('licenseNo')}</strong></Typography></Grid>
                                        <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{cargo?.licenseNo}</Typography></Grid>
                                    </Grid>
                                </>
                            }

                            {/* if transportation type is train */}
                            {cargo?.transportationType === constants.transportationType.train &&
                                <>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('trainNo')}</strong></Typography></Grid>
                                        <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{cargo?.trainNo}</Typography></Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('containerNo')}</strong></Typography></Grid>
                                        <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{cargo?.containerNo}</Typography></Grid>
                                    </Grid>
                                </>
                            }

                            {/* if transportation type is ship */}
                            {cargo?.transportationType === constants.transportationType.ship &&
                                <>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('vesselNo')}</strong></Typography></Grid>
                                        <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{cargo?.vesselNo}</Typography></Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="body1" gutterBottom color="primary"><strong>{t('containerNo')}</strong></Typography></Grid>
                                        <Grid item xs={8}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{cargo?.containerNo}</Typography></Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                </Paper>

                {/* if transportation type is truck */}
                {cargo?.transportationType === constants.transportationType.truck &&
                    <Box mb={1}>
                        <Paper>
                            {/* driver */}
                            <Box px={1}>
                                <Tabs value={selectedTab} onChange={handleTabChange} >
                                    <Tab label={t('driver1')} />
                                    <Tab label={t('driver2')} />
                                    <Tab label={t('escort')} />
                                </Tabs>
                                <TabPanel value={selectedTab} index={0}>

                                    <DriverDetail name={cargo?.driverAName} phone={cargo?.driverAPhone} />
                                </TabPanel>
                                <TabPanel value={selectedTab} index={1}>

                                    <DriverDetail name={cargo?.driverBName} phone={cargo?.driverBPhone} />
                                </TabPanel>
                                <TabPanel value={selectedTab} index={2}>
                                    <DriverDetail name={cargo?.escortName} phone={cargo?.escortPhone} />
                                </TabPanel>
                            </Box>
                        </Paper>
                    </Box>
                }

                {/* vehicle type */}
                <Box my={1} >
                    <Paper>
                        <Box p={2}>
                            <RadioGroup row value={'' + vehicleChecklistType} onChange={(e) => setVehicleChecklistType(e.target.value === '1' ? 1 : 2)}>
                                <FormControlLabel label={t('loading')} value={'1'} control={<Radio />} />
                                <FormControlLabel label={t('unloading')} value={'2'} control={<Radio />} />
                            </RadioGroup>
                        </Box>
                    </Paper>
                </Box>

                {/* initial checklist */}
                {initialChecklist.length > 0 &&
                    (
                        <Box my={1}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body1" color="primary"><strong>{t('initialChecklist')}</strong></Typography>
                                </Box>
                                {
                                    initialChecklist.map(checklist => (
                                        <Box mb={1} key={checklist.id}>
                                            <Box p={2}>
                                                <Grid>
                                                    <Box mb={2}>
                                                        <FormControl>
                                                            <FormLabel>{checklist.name}</FormLabel>
                                                            <RadioGroup row value={answers[checklist.id] ? (answers[checklist.id].isOk ? 'y' : 'n') : 'n'} onChange={(e) => updateIsOk(checklist.id, e.target.value === 'y')}>
                                                                <FormControlLabel label={t('yes')} value={'y'} control={<Radio />} />
                                                                <FormControlLabel label={t('no')} value={'n'} control={<Radio />} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                    <TextField fullWidth label={t('remark')} value={answers[checklist.id] ? (answers[checklist.id].remark ?? "") : ""} InputLabelProps={{
                                                        shrink: true,
                                                    }} onChange={(e) => updateRemark(checklist.id, e.target.value)} />
                                                </Grid>
                                            </Box>

                                        </Box>
                                    ))}
                            </Paper>
                        </Box>
                    )
                }

                {/* before checklist */}
                {beforeActionChecklist.length > 0 &&
                    (
                        <Box my={1}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body1" color="primary"><strong>{t(`before${vehicleChecklistType === 1 ? 'Loading' : 'Unloading'}Checklist`)}</strong></Typography>
                                </Box>

                                {beforeActionChecklist.map(checklist => (
                                    <Box mb={1} key={checklist.id}>
                                        <Box p={2}>
                                            <Grid>
                                                <Box mb={2}>
                                                    <FormControl>
                                                        <FormLabel>{checklist.name}</FormLabel>
                                                        <RadioGroup row value={answers[checklist.id] ? (answers[checklist.id].isOk ? 'y' : 'n') : 'n'} onChange={(e) => updateIsOk(checklist.id, e.target.value === 'y')}>
                                                            <FormControlLabel label={t('yes')} value={'y'} control={<Radio />} />
                                                            <FormControlLabel label={t('no')} value={'n'} control={<Radio />} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Box>
                                                <TextField fullWidth label={t('remark')} value={answers[checklist.id] ? (answers[checklist.id]?.remark ?? "") : ''} InputLabelProps={{
                                                    shrink: true,
                                                }} onChange={(e) => updateRemark(checklist.id, e.target.value)} />
                                            </Grid>
                                        </Box>
                                    </Box>
                                ))}
                            </Paper>
                        </Box>
                    )
                }

                {/* final checklist */}
                {finalChecklist.length > 0 &&
                    (
                        <Box my={1}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body1" color="primary"><strong>{'Final Check'}</strong></Typography>
                                </Box>

                                {finalChecklist.map(checklist => (
                                    <Box mb={1} key={checklist.id}>
                                        <Box p={2}>
                                            <Grid>
                                                <Box mb={2}>
                                                    <FormControl>
                                                        <FormLabel>{checklist.name}</FormLabel>
                                                        <RadioGroup row value={answers[checklist.id] ? (answers[checklist.id].isOk ? 'y' : 'n') : 'n'} onChange={(e) => updateIsOk(checklist.id, e.target.value === 'y')}>
                                                            <FormControlLabel label={t('yes')} value={'y'} control={<Radio />} />
                                                            <FormControlLabel label={t('no')} value={'n'} control={<Radio />} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Box>
                                                <TextField fullWidth label={t('remark')} value={answers[checklist.id] ? (answers[checklist.id]?.remark ?? "") : ''} InputLabelProps={{
                                                    shrink: true,
                                                }} onChange={(e) => updateRemark(checklist.id, e.target.value)} />
                                            </Grid>
                                        </Box>
                                    </Box>
                                ))}
                            </Paper>
                        </Box>
                    )
                }

                {(cargo.vehicleCheckStatus == constants.vehicleCheckStatus.needApproval ||
                    cargo.vehicleCheckStatus == constants.vehicleCheckStatus.approvedBySoc ||
                    cargo.vehicleCheckStatus == constants.vehicleCheckStatus.approvedByTMO ||
                    cargo.vehicleCheckStatus == 0) &&
                    <Box my={1}>
                        <Button variant="contained" color="primary" size="large" fullWidth disabled={submittingData || (cargo.vehicleCheckStatus != constants.vehicleCheckStatus.needApproval && cargo.vehicleCheckStatus != 0)} style={{ marginRight: 8 }} onClick={() => submitData(true)}>{cargo.vehicleCheckStatus == constants.vehicleCheckStatus.needApproval ? t('approve') : t('approved')}</Button>
                    </Box>
                }

                {(cargo.vehicleCheckStatus == constants.vehicleCheckStatus.needApproval ||
                    cargo.vehicleCheckStatus == constants.vehicleCheckStatus.rejectedBySoc ||
                    cargo.vehicleCheckStatus == constants.vehicleCheckStatus.rejectedByTMO ||
                    cargo.vehicleCheckStatus == 0) &&
                    <Button variant="contained" fullWidth size="large" color="secondary" disabled={submittingData || (cargo.vehicleCheckStatus != constants.vehicleCheckStatus.needApproval && cargo.vehicleCheckStatus != 0)} onClick={() => submitData(false)}>{cargo.vehicleCheckStatus == constants.vehicleCheckStatus.needApproval ? t('reject') : t('rejected')}</Button>
                }

            </Box>
            <Alert
                message={t('dataVehicleCheckSubmitted')}
                isOpen={showSuccessAlert}
                onClose={() => setShowSuccessAlert(false)}
                actions={[
                    { label: t('ok'), handler: () => history.goBack() }
                ]} />
        </PageWrapper>
    )
}

export default VehicleCheckPage;