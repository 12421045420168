import { Box, CircularProgress, Grid } from '@material-ui/core';
import React from 'react'

import { makeStyles } from '@material-ui/core/styles';


// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.secondary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));


function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}


const Loader = props => {
    return (<Box  >
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ height: 'calc(100vh - 56px)' }}>
                        <FacebookCircularProgress />
            </Grid>        
    </Box>)
}

export default Loader;