export const allowedRole = {
    transporter: "TRANSPORTER_KERANI",
    receiver: "SITE_USER",
    security: "SOC_WH"
}

export const transportationType = {
    truck: 1,
    train: 2,
    ship: 3,
    airplane: 4
}

export const jobOrderStatus = {
    waiting_cost_approval: 1,
    requested: 2,
    scheduled: 3,
    in_transit: 4,
    finished: 5,
    cancelled: 6
}

export const cargoStatus = {
    waiting: 1,
    in_transit: 2,
    done: 3,
    rejected: 4
}

export const vehicleCheckStatus = {
    needApproval: 1,
    approvedBySoc: 2,
    approvedByTMO: 3,
    rejectedBySoc: 4,
    rejectedByTMO: 5
}

export const jobOrderPOStatus = {
    waiting: 1,
    in_transit: 2,
    done: 3
}

export const jobOrderOBDStatus = {
    waiting: 1,
    in_transit: 2,
    done: 3
}

const constants = {
    allowedRole,
    transportationType,
    jobOrderStatus,
    cargoStatus,
    vehicleCheckStatus,
    jobOrderPOStatus,
    jobOrderOBDStatus
}

export default constants