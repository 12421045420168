import { AppBar, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../components/TabPanel';
import { useQuery } from '../../utilities/urlUtility';
import JobOrderDetail from '../../components/JobOrderDetail';
import VehicleList from '../../components/VehicleList';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { getJobOrderCargoes, getJobOrderDetail } from '../../services/jobOrderService';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import constants from '../../utilities/constants';

const JobOrderDetailPage = props => {
    const query = useQuery();
    const history = useHistory();
    const { t } = useTranslation();
    const id = query.get('id');

    const [selectedTab, setSelectedTab] = useState(parseInt(localStorage.getItem('selected_tab') ?? '0'));
    const [loading, setLoading] = useState(true);
    const [jobOrder, setJobOrder] = useState({});
    const [cargoes, setCargoes] = useState([]);

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
        localStorage.setItem('selected_tab', val)
    }

    const redirectToVehicleCheck = (id) => {
        history.push('/security/vehicle_check?id=' + id, {
            cargo: cargoes.find(c => c.id === id),
            jobOrder: jobOrder
        })
    }

    const redirectToVehicleLoad = (id) => {
        history.push('/security/item_loads?id=' + id, {
            cargo: cargoes.find(c => c.id === id)
        });
    }

    const loadCargoes = async () => {
        try {
            const { data } = await getJobOrderCargoes(id);
            if (data?.success) {
                console.log(data.payload)
                var cargoList = (data?.payload ?? []).filter(x => x.isPickupTruck && x.transportationType == constants.transportationType.truck);
                setCargoes(cargoList);
            }
        } catch { }
    }

    const loadDetail = async () => {
        try {
            const { data } = await getJobOrderDetail(id);
            console.log(data)
            if (data?.success) {
                console.log(data.payload)
                setJobOrder(data?.payload);
            }
        } catch { }
    }

    useEffect(() => {
        const getAllData = async () => {
            setLoading(true);
            await Promise.all([loadDetail(), loadCargoes()]);
            setLoading(false);
        }

        getAllData()
    }, [])

    if (loading) {
        return (
            <PageWrapper title={t('jobOrder')}>
                <Loader />
            </PageWrapper>
        )
    }

    return (
        <PageWrapper title={t('jobOrder')}>
            <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label={t('detail')} />
                    <Tab label={t('vehicles')} />
                    <Tab label={t('loads')} />
                </Tabs>
            </AppBar>
            {loading &&
                <Loader />
            }
            {!loading &&
                <>
                    <TabPanel value={selectedTab} index={0}>
                        <JobOrderDetail jobOrder={jobOrder} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <VehicleList cargoes={cargoes} onSelected={redirectToVehicleCheck} page="vehicle_check" />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <VehicleList cargoes={cargoes} onSelected={redirectToVehicleLoad} page="load_approval_soc" />
                    </TabPanel>
                </>
            }

        </PageWrapper>
    )
}

export default JobOrderDetailPage;