import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PersonOutline, PhoneOutlined } from '@material-ui/icons';

import React from 'react';

const DriverDetail = ({ name, phone }) => {
    return (
        <List>
            <ListItem>
                <ListItemIcon>
                    <PersonOutline className="color-cyan"></PersonOutline>
                </ListItemIcon>
                <ListItemText primary="Name" secondary={name}></ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                        <PhoneOutlined className="color-cyan"></PhoneOutlined>
                </ListItemIcon>
                <ListItemText primary="Phone" secondary={phone}></ListItemText>
            </ListItem>
        </List>
    )
}

export default DriverDetail;