import React from 'react'

const Pill = ({ children, color }) => {
    return (
        <div className={`pill pill-${ color }`} >
          {children}
        </div>
    )
}

Pill.defaultProps = {
    color: 'primary',
}

export default Pill;