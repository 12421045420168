import { Box, Button, Card, CardHeader, CardMedia, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import PageWrapper from '../../components/PageWrapper';
import TabPanel from '../../components/TabPanel';
import constants from '../../utilities/constants';
import DriverDetailForm from './components/DriverDetailForm';
import { getCargoTypes } from '../../services/cargoTypeService'
import { saveJobOrderCargo, getObdByJobOrder, getObdByCargo } from '../../services/jobOrderService'
import { uploadFile, getFile } from '../../services/fileService'
import { getAllSites } from '../../services/siteService'
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../utilities/urlUtility';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import Alert from '../../components/Alert';
import CameraIcon from '@material-ui/icons/PhotoCamera'
import SwapIcon from '@material-ui/icons/ImportExport'
import POISearchBox from '../../components/POISearchBox';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Resizer from 'react-image-file-resizer';
import { resizeImage } from '../../utilities/fileUtil'
/* import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; */

const VehicleFormPage = props => {
    const location = useLocation();
    const query = useQuery();
    const history = useHistory();
    const { t } = useTranslation()
    const { jobOrder, cargo } = location?.state ?? {};
    const cargoId = query ? query.get('id') : null;
    const [license, setLicense] = useState('')
    const [transportationType, setTransportationType] = useState(constants.transportationType.truck)
    const [cargoType, setCargoType] = useState('')
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedObdList, setSelectedObdList] = useState([])
    const [obdList, setObdList] = useState([])
    const [containerNo, setContainerNo] = useState('');
    const [trainNo, setTrainNo] = useState('');
    const [vesselNo, setVesselNo] = useState('');
    const [driverAData, setDriverAData] = useState({ name: null, phone: null, driverLicense: null })
    const [driverBData, setDriverBData] = useState({ name: null, phone: null, driverLicense: null })
    const [escortData, setEscortData] = useState({ name: null, phone: null, kta: null })
    const [cargoTypes, setCargoTypes] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [vehicleRegNo, setVehicleRegNo] = useState(null)
    const [isUploadingVehicleRegNo, setIsUploadingVehicleRegNo] = useState(false)
    const [vehicleRegNoImgUrl, setVehicleRegNoImgUrl] = useState('')
    const [cargoStatus, setCargoStatus] = useState(constants.cargoStatus.waiting)
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true)
    const [sites, setSites] = useState([])
    const [points, setPoints] = useState([null, null])
    const [isPickupTruck, setIsPickupTruck] = useState(false)
    const [notes, setNotes] = useState('')
    const [awbNo, setAwbNo] = useState('')

    const loadCargoTypes = async () => {
        try {
            const { data } = await getCargoTypes();
            if (data?.success) {
                setCargoTypes(data.payload)
            }
        } catch { }
    }

    const loadObdList = async () => {
        try {
            const { data } = await getObdByJobOrder(jobOrder.id);
            if (data.success) {
                setObdList(data.payload)
            }
        } catch { }
    }

    const loadSelectedObdList = async () => {
        if (!cargo) return;
        try {
            const { data } = await getObdByCargo(cargo.id);
            if (data.success) {
                setSelectedObdList(data.payload.map(c => c.id))
            }
        } catch { }
    }

    const loadSites = async () => {
        try {
            const { data } = await getAllSites();
            if (data.success) {
                setSites((data.payload ?? [])
                    .filter(x => x.latitude != null && x.longitude != null)
                    .map(x => ({
                        placeId: x.id,
                        name: x.name ?? "",
                        address: x.address ?? "",
                        latitude: x.latitude,
                        longitude: x.longitude,
                        isSite: true
                    })))
            }
        } catch { }
    }

    const onLicenseChange = (val) => {
        setLicense(val.replace(/[\W_]+/g, "").toUpperCase());
    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await Promise.all([loadCargoTypes(), loadObdList(), loadSelectedObdList(), loadSites()]);
            setLoading(false);
        }
        loadData()
    }, [])

    useEffect(() => {
        if (cargo) {
            setCargoType(cargo.cargoType?.id)
            setTransportationType(cargo.transportationType);
            setLicense(cargo.licenseNo)
            setContainerNo(cargo.containerNo)
            setVesselNo(cargo.vesselNo)
            setTrainNo(cargo.trainNo)
            setDriverAData({
                name: cargo.driverAName,
                phone: cargo.driverAPhone,
                driverLicense: cargo.simADocumentId
            })
            setDriverBData({
                name: cargo.driverBName,
                phone: cargo.driverBPhone,
                driverLicense: cargo.simBDocumentId
            })
            setEscortData({
                name: cargo.escortName,
                phone: cargo.escortPhone,
                kta: cargo.ktaDocumentId
            })
            setVehicleRegNo(cargo.stnkDocumentId)
            setCargoStatus(cargo.status)
            const pointList = [...(cargo.jobOrderCargoPoints ?? [])]
            if (pointList.length < 2) {
                pointList.splice(pointList.length, 0, ...(new Array(2 - pointList.length).fill(null)))
            }
            setPoints(pointList)
            setIsPickupTruck(cargo.isPickupTruck)
            setNotes(cargo.replacementNote)
            setAwbNo(cargo.awbNo)
        } else if (jobOrder) {
            setCargoType(jobOrder.cargoType.id)
        }
    }, [cargo, jobOrder])

    const fetchVehicleRegistrationNo = async () => {
        setIsUploadingVehicleRegNo(true)
        try {
            const { data: vehicleRegNoData } = await getFile(vehicleRegNo)
            const img = vehicleRegNoData?.payload[0];
            setVehicleRegNoImgUrl(img?.url)
        } catch { }

        setIsUploadingVehicleRegNo(false)
    }

    const handleVehicleRegNoChange = async (e) => {
        setIsUploadingVehicleRegNo(true)
        try {
            const file = e.target.files[0];
            const resizedFile = await resizeImage(file);
            console.log(file, resizedFile);
            const { data: uploadData } = await uploadFile(resizedFile)
            if (uploadData?.success) {
                const { id, url } = uploadData?.payload[0]
                setVehicleRegNo(id)
                setVehicleRegNoImgUrl(url)
            }
        } catch {
            setIsUploadingVehicleRegNo(false)
        }
    }

    useEffect(() => {
        if (vehicleRegNo) {
            fetchVehicleRegistrationNo()
        }
    }, [vehicleRegNo])

    const submit = async () => {
        setShowConfirm(false)
        if (!validate()) {
            setShowErrorAlert(true)
            return;
        }

        setIsSaving(true)
        try {
            let oldData = {};
            if (cargo) oldData = { ...cargo }
            const parameter = {
                ...oldData,
                id: cargoId,
                jobOrderId: (jobOrder?.id ?? "").trim() === "" ? null : jobOrder.id,
                cargoTypeId: (cargoType ?? "").trim() === "" ? null : cargoType,
                transportationType: transportationType,
                licenseNo: license,
                containerNo: containerNo,
                vesselNo: vesselNo,
                trainNo: trainNo,
                driverAName: driverAData.name,
                driverAPhone: driverAData.phone,
                driverBName: driverBData.name,
                driverBPhone: driverBData.phone,
                escortName: escortData.name,
                escortPhone: escortData.phone,
                status: cargoStatus,
                simADocumentId: driverAData.driverLicense,
                simBDocumentId: driverBData.driverLicense,
                stnkDocumentId: vehicleRegNo,
                ktaDocumentId: escortData.kta,
                vehicleCheckStatus: cargo ? cargo.vehicleCheckStatus : constants.vehicleCheckStatus.needApproval,
                OBDs: selectedObdList,
                jobOrderCargoPoints: points.filter(x => x != null),
                isPickupTruck: transportationType == constants.transportationType.truck ? isPickupTruck : false,
                replacementNote: notes,
                awbNo: awbNo
            }

            const { data: submitData } = await saveJobOrderCargo(parameter);
            if (submitData?.success) {
                history.goBack();
            }
        } catch { }
        setIsSaving(false)
    }

    const validate = () => {
        const error = {};
        if (transportationType === constants.transportationType.truck) {
            if (!license || license === "") {
                error.license = t('requiredField')
            }

            if ((driverAData?.name ?? "") === "") {
                if (!error.driverA) error.driverA = {}
                error.driverA.name = t('requiredField')
            }

            if ((driverAData?.phone ?? "") === "") {
                if (!error.driverA) error.driverA = {}
                error.driverA.phone = t('requiredField')
            }
        }

        if (selectedObdList.length <= 0 && cargoStatus == constants.cargoStatus.in_transit) {
            error.obd = t('requiredOBD');
        }

        var filteredPoints = points.filter(x => x != null)
        if (filteredPoints.length == 1) {
            error.points = t('requiredPoints')
        }

        setErrors(error)

        return Object.keys(error).length === 0
    }

    const movePointUp = (index) => {
        console.log(index)
        const newOrder = Array.from(points);
        const temp = newOrder[index];
        newOrder[index] = newOrder[index - 1]
        newOrder[index - 1] = temp
        setPoints(newOrder)
    }

    const deletePoint = (index) => {
        const result = Array.from(points);
        result.splice(index, 1);
        setPoints(result)
    }

    const addDestination = () => {
        setPoints([...points, null])
    }

    const onPointChanged = (index, point) => {
        const result = Array.from(points);
        result.splice(index, 1);
        result.splice(index, 0, point);
        setPoints(result)
    }

    if (loading) {
        return (
            <PageWrapper title={t('cargo')}>
                <Loader />
            </PageWrapper>
        )
    }
    return (
        <PageWrapper title={t('cargo')}>
            <Box p={1}>
                <Paper>
                    <Box py={1}>
                        <Container maxWidth="xs">
                            <Typography variant="subtitle2" className="mb-2 color-primary">{t("route")}</Typography>
                            <Grid container justify="center" className="" >
                                {points.map((item, index) => (
                                    <>
                                        {(index > 0) && (
                                            <IconButton size="small"
                                                color="primary"
                                                onClick={() => movePointUp(index)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} >
                                                <SwapIcon />
                                            </IconButton>
                                        )}
                                        <Grid container xs={12} alignItems="center" className="my-2" >
                                            <Grid item xs={index > 1 ? 11 : 12}>
                                                <POISearchBox label={index == 0 ? "Starting Point" : "Destination"}
                                                    sites={sites}
                                                    point={item}
                                                    onPointChanged={(point) => onPointChanged(index, point)}
                                                    disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                            </Grid>
                                            {(index > 1) && (
                                                <Grid item xs={1}>
                                                    <IconButton size="small"
                                                        color="secondary"
                                                        onClick={() => deletePoint(index)}
                                                        disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </>
                                ))}
                                <Button color="primary"
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={() => addDestination()}
                                    disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit}>Add Destination</Button>
                                {errors.points &&
                                    <FormHelperText error>{errors.points}</FormHelperText>
                                }
                            </Grid>

                        </Container>
                    </Box>
                </Paper>
            </Box>

            <Box p={1} >
                <Paper>
                    <Box py={1}>
                        <Container maxWidth="xs" className="py-2">
                            <Typography variant="subtitle2" className="mb-3 color-primary">{t("unitDetail")}</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="type-input-lbl">{t('obd')}</InputLabel>
                                        <Select
                                            labelId="type-input-lbl"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label={t('obd')}
                                            multiple
                                            value={selectedObdList}
                                            onChange={(e) => setSelectedObdList(e.target.value)}
                                            renderValue={(selected) => obdList.filter(x => selected.includes(x.id)).map(x => x.obdNumber).join(', ')}
                                            disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit}>
                                            {obdList.map(o => {
                                                let totalCase = 0;
                                                let totalPack = 0;
                                                for (let i = 0; i < o.jobOrderOBDLoads.length; i++) {
                                                    totalCase += o.jobOrderOBDLoads[i].totalCase;
                                                    totalPack += o.jobOrderOBDLoads[i].totalPack;
                                                }
                                                return (
                                                    <MenuItem value={o.id} key={o.id}>
                                                        <Checkbox checked={selectedObdList.indexOf(o.id) > -1} />
                                                        <ListItemText primary={`${o.obdNumber} - ${totalCase} cases${totalPack > 0 ? " (+" + totalPack + " packs)" : ""}`} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {errors.obd &&
                                        <FormHelperText error>{errors.obd}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="type-input-lbl">{t('type')}</InputLabel>
                                        <Select
                                            labelId="type-input-lbl"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label={t('type')}
                                            value={transportationType}
                                            onChange={(e) => setTransportationType(e.target.value)}
                                            disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit}>
                                            <MenuItem value={constants.transportationType.truck}>{t('truck')}</MenuItem>
                                            <MenuItem value={constants.transportationType.train}>{t('train')}</MenuItem>
                                            <MenuItem value={constants.transportationType.ship}>{t('ship')}</MenuItem>
                                            <MenuItem value={constants.transportationType.airplane}>{t('airplane')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel id="type-input-lbl">{t('status')}</InputLabel>
                                        <Select
                                            labelId="type-input-lbl"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label={t('type')}
                                            value={cargoStatus}
                                            onChange={(e) => setCargoStatus(e.target.value)}
                                            disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done}>
                                            <MenuItem value={constants.cargoStatus.waiting} disabled={((cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit && isPickupTruck)}>{t('waiting')}</MenuItem>
                                            <MenuItem value={constants.cargoStatus.in_transit} disabled={((cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.waiting && isPickupTruck)}>{t('inTransit')}</MenuItem>
                                            <MenuItem value={constants.cargoStatus.done} disabled={cargo == null || cargo.status == constants.cargoStatus.waiting}>{t('done')}</MenuItem>
                                            <MenuItem value={constants.cargoStatus.rejected} disabled={true}>Rejected</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {transportationType === constants.transportationType.truck &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="unit-type-input-lbl">{t('unitType')}</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="unit-type-input-lbl"
                                                    InputLabelProps={{ shrink: true }}
                                                    label={t('unitType')}
                                                    value={cargoType}
                                                    onChange={(e) => setCargoType(e.target.value)}
                                                    disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit}>
                                                    {cargoTypes.map(c => <MenuItem value={c.id} key={c.id}>{c.name} ({c.capacity})</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('licenseNo') + " *"}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                value={license}
                                                onChange={(e) => onLicenseChange(e.target.value)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                            {errors.license &&
                                                <FormHelperText error>{errors.license}</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit || jobOrder.jobOrderStatus != constants.jobOrderStatus.scheduled}
                                                        checked={isPickupTruck}
                                                        onChange={(e) => {
                                                            setIsPickupTruck(e.target.checked)
                                                            if (e.target.checked && cargoStatus == constants.cargoStatus.in_transit) {
                                                                setCargoStatus(constants.cargoStatus.waiting)
                                                            }
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                                label={t('pickUpTruck')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card>
                                                <CardHeader
                                                    action={(
                                                        <div>
                                                            <IconButton
                                                                component="label"
                                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit}>
                                                                <CameraIcon />
                                                                <input type="file" accept="image/*" hidden onChange={handleVehicleRegNoChange} />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                    subheader={t('vehicleRegistrationNo')}
                                                />
                                                {isUploadingVehicleRegNo &&
                                                    <Typography style={{ padding: 10, position: 'absolute' }}>{t('loading')}</Typography>
                                                }
                                                <CardMedia
                                                    style={{
                                                        height: 0,
                                                        paddingTop: '60%', // 16:9
                                                    }}
                                                    image={vehicleRegNoImgUrl}
                                                />
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Tabs value={selectedTab} onChange={(e, val) => setSelectedTab(val)}>
                                                <Tab label={t('driver1')} />
                                                <Tab label={t('driver2')} />
                                                <Tab label={t('escort')} />
                                            </Tabs>
                                            <TabPanel value={selectedTab} index={0}>
                                                <DriverDetailForm
                                                    required={true}
                                                    data={driverAData}
                                                    showKTA={false}
                                                    onChange={setDriverAData} errors={errors?.driverA ?? {}}
                                                    disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                            </TabPanel>
                                            <TabPanel value={selectedTab} index={1}>
                                                <DriverDetailForm
                                                    data={driverBData}
                                                    showKTA={false}
                                                    onChange={setDriverBData}
                                                    disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                            </TabPanel>
                                            <TabPanel value={selectedTab} index={2}>
                                                <DriverDetailForm
                                                    data={escortData}
                                                    showDrivingLicense={false}
                                                    onChange={setEscortData}
                                                    disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                            </TabPanel>
                                        </Grid>
                                    </>
                                }
                                {transportationType === constants.transportationType.train &&
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('trainNo')}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined" value={trainNo}
                                                onChange={(e) => setTrainNo(e.target.value)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('containerNo')}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                value={containerNo}
                                                onChange={(e) => setContainerNo(e.target.value)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                        </Grid>
                                    </>
                                }
                                {transportationType === constants.transportationType.ship &&
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('vesselNo')}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                value={vesselNo}
                                                onChange={(e) => setVesselNo(e.target.value)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('containerNo')}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                value={containerNo}
                                                onChange={(e) => setContainerNo(e.target.value)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                        </Grid>
                                    </>
                                }
                                {transportationType === constants.transportationType.airplane &&
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('awbNo')}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                value={awbNo}
                                                onChange={(e) => setAwbNo(e.target.value)}
                                                disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('notes')}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        multiline rows={4}
                                        disabled={(cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) == constants.cargoStatus.in_transit} />
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                </Paper>
            </Box>
            <Box maxWidth="xs" px={1} mb={1}>
                <Box >
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => setShowConfirm(true)}
                        disabled={(isSaving || (cargo?.status ?? constants.cargoStatus.waiting) === constants.cargoStatus.done || (cargo?.status ?? constants.cargoStatus.waiting) === constants.cargoStatus.rejected)}>{t('save')}</Button>
                </Box>
                {(isSaving) &&
                    <Loader />
                }
            </Box>
            <Alert
                message={t('areYouSure')}
                isOpen={showConfirm}
                onClose={() => setShowConfirm(false)}
                actions={[
                    { label: t('no'), handler: () => setShowConfirm(false) },
                    { label: t('yes'), handler: submit }
                ]} />
            <Alert
                message={t('formError')}
                isOpen={showErrorAlert}
                onClose={() => setShowErrorAlert(false)}
                actions={[
                    { label: t('ok'), handler: () => setShowErrorAlert(false) }
                ]} />
        </PageWrapper >
    )
}

export default VehicleFormPage;