import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import NoDataComponent from '../components/NoDataComponent';
import PageWrapper from '../components/PageWrapper';
import { getObdByCargo, approveCargoLoad } from '../services/jobOrderService';
import { useQuery } from '../utilities/urlUtility';
import constants, { allowedRole } from "../utilities/constants";

const VehicleLoadsPage = props => {
    const query = useQuery()
    const location = useLocation();
    const history = useHistory();
    const cargoId = query.get('id')
    const { t } = useTranslation()
    const { cargo } = location?.state ?? {};
    const [obdList, setObdList] = useState([])
    const [loads, setLoads] = useState([])
    const [notes, setNotes] = useState('')
    const [loading, setLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showSubmitConfirm, setShowSubmitConfirm] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [role, setRole] = useState("");
    const [totalLoad, setTotalLoad] = useState({
        case: 0,
        pack: 0
    })

    useEffect(() => {
        const tempLoad = {};
        const tempTotalLoad = {
            case: 0,
            pack: 0
        };
        obdList.forEach((item) => {
            item.jobOrderOBDLoads.forEach((loadItem) => {
                if (!tempLoad[loadItem.brandId]) {
                    tempLoad[loadItem.brandId] = {
                        name: loadItem.brand,
                        id: loadItem.brandId,
                        total: loadItem.totalCase,
                        totalPack: loadItem.totalPack,
                        sku: loadItem.sku
                    }
                } else {
                    tempLoad[loadItem.brandId].total += loadItem.totalCase
                    tempLoad[loadItem.brandId].totalPack += loadItem.totalPack
                }
                tempTotalLoad.case += loadItem.totalCase;
                tempTotalLoad.pack += loadItem.totalPack;
            })
        })
        setLoads(Object.keys(tempLoad).map(item => tempLoad[item]));
        setTotalLoad(tempTotalLoad)
    }, [obdList])

    useEffect(() => {
        const getObds = async () => {
            if (!cargoId) return;
            setLoading(true)
            try {
                const { data } = await getObdByCargo(cargoId);
                if (data.success) {
                    setObdList(data.payload)
                }
            } catch { }
            setLoading(false)
        }

        getObds()

        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if ((currentUser?.roleKey ?? []).indexOf(constants.allowedRole.transporter) >= 0) {
            setIsApproved((cargo?.transporterLoadApprovalStatus ?? 1) == 1)
        } else if ((currentUser?.roleKey ?? []).indexOf(constants.allowedRole.security) >= 0) {
            setIsApproved((cargo?.socLoadApprovalStatus ?? 1) == 1);
        }
    }, [])

    const submit = async () => {
        setShowSubmitConfirm(false)
        setIsSubmitting(true)
        try {
            let data = (await approveCargoLoad(cargoId, notes)).data;
            if (data?.success) {
                history.goBack()
            } else {
                alert(data?.errors)
            }
        } catch { }
        setIsSubmitting(false)
    }

    return (
        <PageWrapper title={t('loads')}>
            {loading &&
                <Loader />
            }
            {!loading && (loads ?? []).length === 0 &&
                <NoDataComponent />
            }

            {
                loads.length > 0 && (
                    <Box p={1}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {loads.map(load => (
                                        <TableRow key={load.id}>
                                            <TableCell component="th" scope="row">
                                                <Grid item xs={10}>
                                                    <Typography variant="body2" color="primary"><b>{load.sku}</b></Typography>
                                                    <Typography variant="body2">{load.name}</Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="right">{load.total} cases{load.totalPack > 0 ? " (+" + load.totalPack + " packs)" : ""}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell component="th" scope="row" align="right">
                                            <Typography variant="body2" color="primary"><b>Total</b></Typography>
                                        </TableCell>
                                        <TableCell align="right" color="primary">
                                            <Typography variant="body2" color="primary"><b>{totalLoad.case} cases{totalLoad.pack > 0 ? " (+" + totalLoad.pack + " packs)" : ""}</b></Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            }

            <Box px={1}>
                <Paper>
                    <Box p={2}>
                        {!isApproved &&
                            <TextField label={t('notes')} fullWidth InputLabelProps={{ shrink: true }} variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} multiline rows={4} />
                        }

                        <Box mt={2}>
                            <Button className={isApproved ? "btn-success" : ""} variant="contained" color="primary" size="large" fullWidth style={{ marginRight: 8 }} onClick={() => setShowSubmitConfirm(true)} disabled={(loading || isSubmitting || isApproved || loads.length == 0)}>{t(isApproved ? 'approved' : 'approve')}</Button>
                        </Box>
                    </Box>

                </Paper>
            </Box>

            <Alert
                message={t('areYouSure')}
                isOpen={showSubmitConfirm}
                onClose={() => setShowSubmitConfirm(false)}
                actions={[
                    { label: t('no'), handler: () => setShowSubmitConfirm(false) },
                    { label: t('yes'), handler: submit }
                ]} />
        </PageWrapper>
    )
}

export default VehicleLoadsPage;