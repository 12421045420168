import axios from 'axios';

export const getAllSites = () => {
    return axios.get(`${window.Configuration['API_URL']}/api/Site/GetAllSites`)
}

export const getSiteDetail = (userId) => {
    return axios.get(`${window.Configuration['API_URL']}/api/Site/GetSiteByUserId/${userId}`)
}

export const updateSite = (site) => {
    return axios.post(`${window.Configuration["API_URL"]}/api/Site/UpdateSite`, site)
}