import { Box, Button, Container, Divider, Grid, GridList, GridListTile, GridListTileBar, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useQuery } from '../../utilities/urlUtility';
import PageWrapper from '../../components/PageWrapper';
import AccordionItem from '../../components/AccordionItem';
import { useTranslation } from 'react-i18next';
import { getJobOrderCargoes, getJobOrderDetail, getObdByCargo, markCargoAsReceived, getDeliveryDocuments } from '../../services/jobOrderService'
import Loader from '../../components/Loader';
import SmallLoader from '../../components/SmallLoader';
import constants from '../../utilities/constants';
import NoDataComponent from '../../components/NoDataComponent';
import VehicleListItem from '../../components/VehicleListItem';
import Alert from '../../components/Alert';
import ImageGalleryItem from '../../components/ImageGalleryItem'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'scroll'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        // paddingTop: 10
    },
    titleBar: {
        background: 'linear-gradient(to top, rgba(14,43,99,1) 0%, rgba(14,43,99,0.5) 90%, rgba(14,43,99,0) 100%)',
    },
}));

const JobOrderDetailPage = props => {
    const query = useQuery();
    const id = query.get('id');
    const { t } = useTranslation();
    const [jobOrder, setJobOrder] = useState({});
    const [loading, setLoading] = useState(true);
    const [site, setSite] = useState({});
    const [isSendingData, setIsSendingData] = useState(false)
    const [cargoes, setCargoes] = useState([])
    const [POs, setPOs] = useState([])
    const [isLoadingCargo, setIsLoadingCargo] = useState(false)
    const [cargoLoads, setCargoLoads] = useState({})
    const [showSubmitConfirm, setShowSubmitConfirm] = useState([])
    const [cargoObds, setCargoObds] = useState({})
    const classes = useStyles();
    const [notes, setNotes] = useState({})
    const [photos, setPhotos] = useState({})
    const [photoNotes, setPhotoNotes] = useState({})

    const loadDetail = async () => {
        try {
            const { data } = await getJobOrderDetail(id);
            if (data?.success) {
                setJobOrder(data?.payload);
            }
        } catch { }
    }

    const loadCargoes = async () => {
        setIsLoadingCargo(true)
        try {
            const { data } = await getJobOrderCargoes(id);
            if (data?.success) {
                const inTransitCargoes = (data?.payload ?? [])//.filter(c => c.status === constants.cargoStatus.in_transit)
                await filterCargoByOBDs(inTransitCargoes)
            }
        } catch { }
        setIsLoadingCargo(false)
    }

    const filterCargoByOBDs = async (inTransitCargoes) => {
        const cargoList = []
        const currentCargoLoads = {}
        const cargoObdList = {}
        const noteList = {};
        const photoList = {};
        const photoNoteList = {};
        for (let i = 0; i < inTransitCargoes.length; i++) {
            const obds = await getCargoObd(inTransitCargoes[i].id)
            const filteredObds = obds.filter(obd => POs.map(po => po.id).includes(obd.jobOrderPOId))
            if (filteredObds.length > 0) {
                cargoList.push(inTransitCargoes[i])
                currentCargoLoads[inTransitCargoes[i].id] = iterateLoads(filteredObds);
                cargoObdList[inTransitCargoes[i].id] = filteredObds;

                const delivery = await loadCargoDelivery(inTransitCargoes[i].id)
                noteList[inTransitCargoes[i].id] = delivery.notes;
                photoList[inTransitCargoes[i].id] = delivery.photos;
                photoNoteList[inTransitCargoes[i].id] = delivery.photoNotes;
            }
        }
        setCargoes(cargoList);
        setShowSubmitConfirm(new Array(cargoList.length).fill(false))
        setCargoLoads(currentCargoLoads)
        setCargoObds(cargoObdList)
        setNotes(noteList)
        setPhotos(photoList)
        setPhotoNotes(photoNoteList)
    }

    const onNotesChange = (cargoId, note) => {
        const newNotes = { ...notes }
        newNotes[cargoId] = note
        setNotes(newNotes)
    }

    const onImageChange = (cargoId, photoIndex, photo) => {
        const newPhotos = { ...photos }
        newPhotos[cargoId][photoIndex] = photo;
        setPhotos(newPhotos)
    }

    const onPhotoNoteChange = (cargoId, photoIndex, note) => {
        const newPhotoNotes = { ...photoNotes };
        newPhotoNotes[cargoId][photoIndex] = note;
        setPhotoNotes(newPhotoNotes)
    }

    const iterateLoads = (obdList) => {
        const tempLoad = {};
        obdList.forEach((item) => {
            item.jobOrderOBDLoads.forEach((loadItem) => {
                if (!tempLoad[loadItem.brandId]) {
                    tempLoad[loadItem.brandId] = {
                        name: loadItem.brand,
                        id: loadItem.brandId,
                        total: loadItem.totalCase,
                        totalPack: loadItem.totalPack
                    }
                } else {
                    tempLoad[loadItem.brandId].total += loadItem.totalCase
                    tempLoad[loadItem.brandId].totalPack += loadItem.totalPack
                }
            })
        })

        return Object.keys(tempLoad).map(item => tempLoad[item]);
    }

    const getCargoObd = async (cargoId) => {
        if (!cargoId) return [];
        try {
            const { data } = await getObdByCargo(cargoId);
            if (data.success) {
                return data.payload
            }
        } catch { }
        return []
    }

    const loadData = async () => {
        setLoading(true);
        await Promise.all([loadDetail()])
        setLoading(false);
    }

    useEffect(() => {
        loadData();
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        if (currentUser.site && currentUser.site.length > 0) {
            const sites = currentUser.site.filter(x => x.roleKey === constants.allowedRole.receiver)
            setSite(sites.length > 0 ? sites[0] : {});
        }
    }, []);

    useEffect(() => {
        if (POs && POs.length > 0)
            loadCargoes();
    }, [POs])

    useEffect(() => {
        if (site && jobOrder.jobOrderPOs && jobOrder.jobOrderPOs.length > 0) {
            const myPOs = jobOrder.jobOrderPOs.filter(po => po.siteDestinationPlantId === site.plantId)
            setPOs(myPOs)
        }
    }, [site, jobOrder])

    const markAsReceived = async (cargoId) => {
        setIsSendingData(true)
        const jobOrderCargoDeliverySite = {
            siteId: site.id,
            remark: notes[cargoId],
            jobOrderCargoDocuments: photos[cargoId].filter(val => val != null).map((val, idx) => ({
                blobItemId: val.id,
                remark: photoNotes[cargoId][idx]
            }))
        }
        try {
            await markCargoAsReceived(cargoId, cargoObds[cargoId].map(x => ({ id: x.id, totalBrokenCases: x.totalBrokenCases || 0 })), jobOrderCargoDeliverySite)
        } catch { }
        setIsSendingData(false)
        setLoading(true)
        await loadCargoes();
        setLoading(false)
    }

    const displayAlert = (value, index) => {
        const newShowSubmitConfirm = [...showSubmitConfirm];
        newShowSubmitConfirm[index] = value
        setShowSubmitConfirm(newShowSubmitConfirm)
    }

    const isDelievered = (cargoObd) => {
        return !((cargoObd ?? []).filter(c => c.confirmedBySite != true).length > 0);
    }

    const loadCargoDelivery = async (cargoId) => {
        try {
            const { data: resp } = await getDeliveryDocuments(cargoId, site.id);
            const payload = resp?.payload;
            if (payload) {
                return {
                    notes: payload.remark,
                    photos: (payload.jobOrderCargoDocuments ?? []).map(x => x ? ({
                        id: x.blobItemId,
                        url: x.url
                    }) : null),
                    photoNotes: (payload.jobOrderCargoDocuments ?? []).map(x => x ? x.remark : null)
                }
            }
        } catch { }

        return {
            notes: "",
            photos: [null, null, null],
            photoNotes: ["", "", ""]
        }
    }

    const updateBrokenCase = (value, cargoId, obdId) => {
        const newCargoObds = { ...cargoObds }
        const cargoObd = newCargoObds[cargoId]
        const obd = cargoObd.filter(obd => obd.id == obdId)[0]
        obd.totalBrokenCases = parseInt(value)
        setCargoObds(newCargoObds)
    }

    if (loading) {
        return (
            <PageWrapper title={t('jobOrder')}>
                <Loader />
            </PageWrapper>
        )
    }

    return (
        <PageWrapper title={t('jobOrder')}>
            <Box p={1} >
                <Paper className="card card-gradient mb-2">
                    <Box px={2} pt={2}>
                        <Grid container>

                            {/* number */}
                            <Grid container>
                                <Grid item xs={6}><Typography variant="body1" gutterBottom color="#fff"><strong>{t('documentNo')}</strong></Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{`#${jobOrder?.joNumber}`}</Typography></Grid>
                            </Grid>

                            {/* company name */}
                            <Grid container>
                                <Grid item xs={6}><Typography variant="body1" gutterBottom color="#fff"><strong>{t('transporter')}</strong></Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.transporter?.companyName}</Typography></Grid>
                            </Grid>

                            {/* origin */}
                            <Grid container>
                                <Grid item xs={6}><Typography variant="body1" gutterBottom color="#fff"><strong>{t('origin')}</strong></Typography></Grid>
                                <Grid item xs={6}><Typography variant="body1" gutterBottom color="textSecondary" className="text-right">{jobOrder?.siteOrigin?.name}</Typography></Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Paper>

                {
                    (POs ?? []).map(po => (
                        <AccordionItem
                            key={po.id}
                            title={po.poNumber}
                            subtitle={po.siteDestinationName}
                            defaultExpanded={false}>
                            <Container>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {po.jobOrderPOLoads.map((load, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        <Grid item xs={10}>
                                                            <Typography variant="body2" color="primary"><b>{load.sku}</b></Typography>
                                                            <Typography variant="body2">{load.brandName}</Typography>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="right">{load.totalCase} cases{load.totalPack > 0 ? " (+" + load.totalPack + " packs)" : ""}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Container>
                        </AccordionItem>
                    ))
                }
            </Box>
            {isLoadingCargo &&
                <SmallLoader />
            }
            <Box px={1}>
                {!isLoadingCargo && cargoes.length > 0 &&
                    <>
                        {cargoes.map((cargo, index) =>
                            <VehicleListItem cargo={cargo}>
                                <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                                <Grid>
                                    {cargoObds[cargo.id].map(obd =>
                                        <>
                                            <Box py={2}>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table">
                                                        <TableBody>
                                                            <TableRow style={{ backgroundColor: "#0e2b63" }}>
                                                                <TableCell component="th" scope="row" style={{ color: "#fff" }}><b>{obd.obdNumber}</b></TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                            {(obd.jobOrderOBDLoads).map(load =>
                                                                <TableRow key={load.id}>
                                                                    <TableCell component="th" scope="row">
                                                                        <Grid item xs={10}>
                                                                            <Typography variant="body2" color="primary"><b>{load.sku}</b></Typography>
                                                                            <Typography variant="body2">{load.brand}</Typography>
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell align="right">{load.totalCase} cases{load.totalPack > 0 ? " (+" + load.totalPack + " packs)" : ""}</TableCell>
                                                                </TableRow>
                                                            )}
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" style={{ color: "#E72581" }}>{t('brokenCase')}</TableCell>
                                                                <TableCell align="right">
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        disabled={isDelievered(cargoObds[cargo.id])}
                                                                        value={obd.totalBrokenCases || ""}
                                                                        onChange={(e) => updateBrokenCase(e.target.value, cargo.id, obd.id)} />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </>
                                    )}
                                    {(!isDelievered(cargoObds[cargo.id]) || (photos[cargo.id] && photos[cargo.id][0]) || (photos[cargo.id] && photos[cargo.id][0]) || (photos[cargo.id] && photos[cargo.id][0])) &&
                                        <Box py={2} className="gallery__container">
                                            <GridList spacing={10} className={[classes.gridList, 'gallery__grid']} >
                                                {(!isDelievered(cargoObds[cargo.id]) || (photos[cargo.id] && photos[cargo.id][0])) &&
                                                    <ImageGalleryItem
                                                        imgSrc={photos[cargo.id] ? photos[cargo.id][0]?.url : ""}
                                                        note={photoNotes[cargo.id] ? photoNotes[cargo.id][0] : ""}
                                                        onChange={(image) => onImageChange(cargo.id, 0, image)}
                                                        onNoteChange={(note) => onPhotoNoteChange(cargo.id, 0, note)}
                                                        disabled={isDelievered(cargoObds[cargo.id])} />
                                                }
                                                {(!isDelievered(cargoObds[cargo.id]) || (photos[cargo.id] && photos[cargo.id][1])) &&
                                                    <ImageGalleryItem
                                                        imgSrc={photos[cargo.id] ? photos[cargo.id][1]?.url : ""}
                                                        note={photoNotes[cargo.id] ? photoNotes[cargo.id][1] : ""}
                                                        onChange={(image) => onImageChange(cargo.id, 1, image)}
                                                        onNoteChange={(note) => onPhotoNoteChange(cargo.id, 1, note)}
                                                        disabled={isDelievered(cargoObds[cargo.id])} />
                                                }
                                                {(!isDelievered(cargoObds[cargo.id]) || (photos[cargo.id] && photos[cargo.id][2])) &&
                                                    <ImageGalleryItem
                                                        imgSrc={photos[cargo.id] ? photos[cargo.id][2]?.url : ""}
                                                        note={photoNotes[cargo.id] ? photoNotes[cargo.id][2] : ""}
                                                        onChange={(image) => onImageChange(cargo.id, 2, image)}
                                                        onNoteChange={(note) => onPhotoNoteChange(cargo.id, 2, note)}
                                                        disabled={isDelievered(cargoObds[cargo.id])} />
                                                }
                                            </GridList>
                                        </Box>
                                    }
                                    <Box py={2}>
                                        <TextField
                                            label={t('notes')}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            value={notes[cargo.id]}
                                            disabled={isDelievered(cargoObds[cargo.id])}
                                            onChange={(e) => onNotesChange(cargo.id, e.target.value)} />
                                    </Box>
                                    <Box mt={1}>
                                        <Button
                                            variant="contained"
                                            className={isDelievered(cargoObds[cargo.id]) ? "" : "btn-success"}
                                            color="#fff"
                                            fullWidth
                                            size="large"
                                            disabled={isDelievered(cargoObds[cargo.id])}
                                            onClick={() => displayAlert(true, index)}
                                        >
                                            {isDelievered(cargoObds[cargo.id]) ? t('received') : t('markAsReceived')}
                                        </Button>
                                    </Box>
                                    <Alert
                                        message={t('areYouSure')}
                                        isOpen={showSubmitConfirm[index]}
                                        onClose={() => displayAlert(false, index)}
                                        actions={[
                                            { label: t('no'), handler: () => displayAlert(false, index) },
                                            {
                                                label: t('yes'), handler: () => {
                                                    displayAlert(false, index);
                                                    markAsReceived(cargo.id);
                                                }
                                            }
                                        ]} />
                                </Grid>
                            </VehicleListItem>
                        )}
                    </>
                }
                {!isLoadingCargo && cargoes.length <= 0 &&
                    <NoDataComponent />
                }
            </Box>
        </PageWrapper>
    )
}

export default JobOrderDetailPage;