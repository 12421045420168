import { Box, Card, CardHeader, CardMedia, FormHelperText, IconButton, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import CameraIcon from '@material-ui/icons/PhotoCamera'
import { uploadFile, getFile } from '../../../services/fileService'
import Loader from '../../../components/Loader';
import { useTranslation } from 'react-i18next';
import { resizeImage } from '../../../utilities/fileUtil';

const DriverDetailForm = ({ data, showName, showPhone, showDrivingLicense, showKTA, onChange, errors, required, disabled }) => {
    const { t } = useTranslation()
    const [drivingLicenseImgUrl, setDrivingLincenseImgUrl] = useState('')
    const [isUploadingDrivingLicense, setIsUploadingDrivingLicense] = useState(false);
    const [ktaImgUrl, setKtaImgUrl] = useState('')
    const [isUploadingKta, setIsUploadingKta] = useState(false);
    const onChangeName = (value) => {
        if (onChange) onChange({ ...data, name: value });
    }

    const onChangePhone = (value) => {
        if (onChange) onChange({ ...data, phone: value })
    }

    const handleDrivingLicenseChange = async (e) => {
        setIsUploadingDrivingLicense(true)
        try {
            const file = e.target.files[0];
            const resizedFile = await resizeImage(file);
            const { data: uploadData } = await uploadFile(resizedFile)
            const { id } = uploadData?.payload[0]
            if (onChange) onChange({ ...data, driverLicense: id })
        } catch { }
        setIsUploadingDrivingLicense(false)
    }

    const handleKtaChange = async (e) => {
        setIsUploadingKta(true)
        try {
            const file = e.target.files[0];
            const resizedFile = await resizeImage(file);
            const { data: uploadData } = await uploadFile(resizedFile)
            const { id } = uploadData?.payload[0]
            if (onChange) onChange({ ...data, kta: id })
        } catch { }
        setIsUploadingKta(false)
    }

    const fetchDriverLicense = async () => {
        setIsUploadingDrivingLicense(true)
        try {
            const { data: respData } = await getFile(data.driverLicense)
            const img = respData?.payload[0];
            setDrivingLincenseImgUrl(img?.url)
        } catch { }
        setIsUploadingDrivingLicense(false)
    }

    const fetchKta = async () => {
        setIsUploadingKta(true)
        try {
            const { data: respData } = await getFile(data.kta)
            const img = respData?.payload[0];
            setKtaImgUrl(img?.url)
        } catch { }
        setIsUploadingKta(false)
    }

    useEffect(() => {
        if (data?.driverLicense) fetchDriverLicense();
    }, [data.driverLicense])

    useEffect(() => {
        if (data?.kta) fetchKta();
    }, [data.kta])
    return (
        <div>
            {showName &&
                (
                    <Box mt={2}>
                        <TextField
                            label={t('name') + (required ? " *" : "")}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            value={data?.name ?? ''}
                            onChange={(e) => onChangeName(e.target.value)}
                            disabled={disabled} />
                        {errors.name &&
                            <FormHelperText error>{errors.name}</FormHelperText>
                        }
                    </Box>
                )
            }

            {showPhone &&
                (
                    <Box mt={2}>
                        <TextField
                            label={t('phone') + (required ? " *" : "")}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            value={data?.phone ?? ''}
                            onChange={(e) => onChangePhone(e.target.value)}
                            disabled={disabled} />
                        {errors.phone &&
                            <FormHelperText error>{errors.phone}</FormHelperText>
                        }
                    </Box>
                )
            }
            {showDrivingLicense &&
                (
                    <Box mt={2}>
                        <Card>
                            <CardHeader
                                action={(
                                    <div>
                                        <IconButton component="label" disabled={disabled}>
                                            <CameraIcon />
                                            <input type="file" accept="image/*" hidden onChange={handleDrivingLicenseChange} />
                                        </IconButton>
                                    </div>
                                )}
                                subheader={t('driverId')}
                            />
                            {isUploadingDrivingLicense &&
                                <Typography style={{ padding: 10, position: 'absolute' }}>{t('loading')}</Typography>
                            }
                            <CardMedia
                                style={{
                                    height: 0,
                                    paddingTop: '60%', // 16:9
                                }}
                                image={drivingLicenseImgUrl}
                            />
                        </Card>
                    </Box>
                )
            }
            {showKTA &&
                (
                    <Box mt={2}>
                        <Card>
                            <CardHeader
                                action={
                                    <IconButton component={'label'} disabled={disabled}>
                                        <CameraIcon />
                                        <input type="file" accept="image/*" hidden onChange={handleKtaChange} />
                                    </IconButton>
                                }
                                subheader={t('escortId')}
                            />
                            {isUploadingKta &&
                                <Loader />
                            }
                            <CardMedia
                                style={{
                                    height: 0,
                                    paddingTop: '60%', // 16:9
                                }}
                                image={ktaImgUrl}
                            />
                        </Card>
                    </Box>
                )
            }

        </div>
    )
}

DriverDetailForm.defaultProps = {
    data: {},
    showName: true,
    showPhone: true,
    showDrivingLicense: true,
    showKTA: true,
    errors: {},
    required: false,
    disabled: false
}

export default DriverDetailForm;