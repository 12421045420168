import { Box, Button, ButtonBase, Grid, GridListTile, GridListTileBar, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Clear';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SubjectIcon from '@material-ui/icons/Subject';
import { resizeImage } from '../utilities/fileUtil';
import { uploadFile } from '../services/fileService';
import Alert from './Alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'scroll',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        paddingTop: 10
    },
    titleBar: {
        background: 'linear-gradient(to top, rgba(14,43,99,1) 0%, rgba(14,43,99,0.5) 90%, rgba(14,43,99,0) 100%)',
    },
}));

const ImageGalleryItem = ({ imgSrc, style, onChange, note, onNoteChange, disabled }) => {
    const { t } = useTranslation()
    const classes = useStyles();
    const [isUploading, setIsUploading] = useState(false)
    const [isNotesDisplayed, setIsNotesDisplayed] = useState(false)
    const [isImageDisplayed, setIsImageDisplayed] = useState(false)

    const handleImageChange = async (e) => {
        setIsUploading(true)
        try {
            const file = e.target.files[0];
            const resizedFile = await resizeImage(file);
            const { data: uploadData } = await uploadFile(resizedFile)
            const { id, url } = uploadData?.payload[0]
            if (onChange) onChange({ id, url })
        } catch { }
        setIsUploading(false)
    }

    const handleNoteChange = (e) => {
        if (onNoteChange) onNoteChange(e.target.value)
    }

    const clearImage = () => {
        if (onChange) onChange(null)
        if (onNoteChange) onNoteChange("")
    }

    const onImageClicked = () => {
        setIsImageDisplayed(true);
    }

    return (
        <GridListTile style={{ ...style}} className="gallery__item">
            {isUploading &&
                <Typography style={{ padding: 10, position: 'absolute', zIndex: 999 }}>{t('loading')}</Typography>
            }
            {imgSrc && (
                <ButtonBase onClick={() => onImageClicked()} style={{ width: '100%', height: '100%', padding: 0 }}><img src={imgSrc} className="gallery__img" /></ButtonBase>
            )}

            <GridListTileBar
                classes={{ root: classes.titleBar }}
                actionIcon={
                    <>
                        {!disabled &&
                            <IconButton component="label">
                                <PhotoCameraIcon style={{ color: 'white' }} fontSize="small" />
                                <input type="file" accept="image/*" hidden onChange={handleImageChange} />
                            </IconButton>
                        }
                        {imgSrc &&
                            <>
                                <IconButton onClick={() => setIsNotesDisplayed(true)}>
                                    <SubjectIcon style={{ color: 'white' }} fontSize="small" />
                                </IconButton>
                                {!disabled &&
                                    <IconButton onClick={clearImage}>
                                        <DeleteIcon style={{ color: 'white' }} fontSize="small" />
                                    </IconButton>
                                }
                            </>
                        }

                    </>
                }
            />
            <Alert
                isOpen={isNotesDisplayed}
                onClose={() => setIsNotesDisplayed(false)}
                title={t("notes")}
                actions={[
                    { label: t("done"), handler: () => setIsNotesDisplayed(false) },
                ]}
            >
                <Box mb={2}>
                    <TextField
                        fullWidth
                        value={note}
                        multiline
                        disabled={disabled}
                        onChange={handleNoteChange}
                    />
                </Box>
            </Alert>
            <Alert
                isOpen={isImageDisplayed}
                onClose={() => setIsImageDisplayed(false)}
                actions={[
                    { label: t("done"), handler: () => setIsImageDisplayed(false) },
                ]}
            >
                <img src={imgSrc} className="gallery__img" />
            </Alert>
        </GridListTile>
    )
}

ImageGalleryItem.defaultProps = {
    disabled: false
}

export default ImageGalleryItem